import {styled} from '@mui/material/';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Game from 'gameslib';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Arrow_down_white from '../../Assets/Images/Arrow_down_white.svg';
import Arrow_left_white from '../../Assets/Images/Arrow_left_white.svg';
import Arrow_right_white from '../../Assets/Images/Arrow_right_white.svg';
import Arrow_up_white from '../../Assets/Images/Arrow_up_white.svg';
import CheetiRight from '../../Assets/Images/CheetiRight.svg';
import HorizontalBar from '../../Assets/Images/HorizontalBar.svg';
import ErrorBox from './FeedbackBox/ErrorBox';
import GoodJobFeedbackBox from './FeedbackBox/GoodJobFeedbackBox';
import VideoIcon from '../../Assets/Images/VideoIcon.svg';
import { loadGamefromLib } from '../../Utils/LoadGamefromLib';
import { carryForwardPreviousLevelCode } from '../../Utils/CarryForwardCode';
import PlayVideo from './PlayVideo';
import { calculateHeight } from '../../Utils/helper';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

function arrow_keys_handler(e) {
  if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}
export default function Game1(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [gameInfoObj, setGameInfoObj] = useState();
  const [gameInfo, setGameInfo] = useState();
  const [currentLevel, setCurrentLevel] = useState(1);
  const [previousLevel, setPreviousLevel] = useState(1);
  const [runGameObj, setRunGameObj] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something’s not right!');
  const [showSuccess, setShowSucess] = useState(false);
  const [sucessMsg, setSucessMsg] = useState('That was great!');
  const [showFeedback, setShowFeedback] = useState(true);
  const [codingGameSubmitButtonText, setCodingGameSubmitButtonText] = useState('Run Code');
  const [helpVideoPopup, setHelpVideoPopup] = useState(false);
  const [intructionBoxHeight, setInstructionBoxHeight] = useState(64.6262);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [currentLevelJson, setCurrentLevelJson] = useState();
  const [levelsJson, setLevelsJson] = useState([]);
  const [codeCarryForwardLevels, setCodeCarryForwardLevels] = useState([]);
  const { search } = useLocation();
  let view;
  if (new URLSearchParams(search).get('view')) {
    view = true;
  } else {
    view = false;
  }
  const blocksCountContainerRef = useRef(null);
  const blocksCountRef = useRef(null);
  const gameInstructionRef = useRef(null);

  useEffect(() => {
    if(props.currentSection === 'game'){
      (props.teacher && !props.teacherView) ? document.title = "CSSoch | Review Game" : document.title = "CSSoch | Game";
      if (!isRendredOnce) {
        const fetchPromises = props.strapiData.Game[0].Levels.map((level) => fetch(level['Config File']).then((res) => res.json()));
        Promise.all(fetchPromises)
          .then((results) => {
            results.map((json, index) => {
              if (json.hasOwnProperty('carryForwardCode')) setCodeCarryForwardLevels((prevLevels) => [...prevLevels, index + 1]);
            });
            const updatedLevelsJson = [...levelsJson, ...results];
            setLevelsJson(updatedLevelsJson);
            setGameInfoObj(props.strapiData.Game[0]);
            setGameInfo(loadGamefromLib(props.strapiData.Game[0], false, false));
          })
          .catch((error) => {
            console.error('Error: ', error);
          });
      } else if (runGameObj && gameInfo.options.structure === 'codingEnvironment') runGameObj.getBlocksCount();
    }
    else {
      window.removeEventListener('keydown', arrow_keys_handler, false);
      if (runGameObj) runGameObj.restart();
    }
    setShowError(false);
    setShowSucess(false);
  }, [props.currentSection]);
  useEffect(() => {
    if (gameInfo) {
      if (!runGameObj) setRunGameObj(new Game(gameInfo.gameType));
      setupGameStatistics();
      setupGameLevelButtons();
      setIsLoading(false);
    }
  }, [gameInfo]);
  useEffect(() => {
    if (!isLoading) {
      if (runGameObj && !isRendredOnce) {
        // Setup game with options
        runGameObj.setup(gameInfo.options, false);
        runGameObj.loadBlocklyImgs(gameInfo.blocklyAsset);
        // Load the assets
        runGameObj.loadAssets(gameInfo.assets);
        setIsRenderedOnce(true);
      }
      updateActiveClass(currentLevel - 1, currentLevel - 1);
      if (props.teacher && !props.teacherView) updateLevelColors();
      loadLevel(currentLevel);
      return () => {
        runGameObj.restart();
      };
    }
  }, [isLoading]);
  useEffect(() => {
    if (!isLoading) {
      loadLevel(currentLevel);
    }
  }, [currentLevel]);
  useEffect(() => {
    if (currentLevelJson && currentLevelJson.hasOwnProperty('maxBlocks')) runGameObj.getBlocksCount();
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (blocksCountRef.current) {
          if (blocksCountRef.current.innerText > currentLevelJson.maxBlocks) {
            blocksCountContainerRef.current.style.border = '0.2vw solid #B30A74';
            blocksCountContainerRef.current.style.borderTop = 'none';
          } else {
            blocksCountContainerRef.current.style.border = 'none';
          }
        }
      });
    });
    if (blocksCountRef.current) {
      observer.observe(blocksCountRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  }, [currentLevelJson, blocksCountRef]);
  useEffect(() => {
    // Additional height calculation when instruction content changes
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
  }, [gameInstructionRef.current?.clientHeight]);

  function setupGameStatistics() {
    if (!props.statusStorage.game_levels.length) {
        const gameLevels= [];
      for (let i = 0; i < gameInfo.levels.length; i++) {
        gameLevels.push({
          feedback: 'unavailable',
          code: '',
          attempts: 0,
          status:'incomplete'
        });
      }
      props.updateStatusStorage({'game_levels' : gameLevels},'game_levels');
    }
  }
  function setupGameLevelButtons() {
    let currentLvl = 1;
    for (let i = 0; i < gameInfo.levels.length; i++) {
      if (props.statusStorage.game_levels[i]?.status === 'ongoing') {
        setCurrentLevel(i + 1);
        setPreviousLevel(i + 1);
        currentLvl = i + 1;
      }
    }
    if (currentLvl === 1 && props.statusStorage.game_levels[0]?.status !== 'ongoing') {
      for (let i = 0; i < props.statusStorage.game_levels.length; i++) {
        if (props.statusStorage.game_levels[i]?.status === 'incomplete') {
          const gameLevelsStatus = [...props.statusStorage.game_levels];
          gameLevelsStatus[i].status = 'ongoing';
          props.updateStatusStorage({'game_levels' : gameLevelsStatus});
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          break;
        }
      }
    }
  }
  function updateActiveClass(level, currentLevel) {
    document
      .querySelectorAll('.game-button-holder button')
      [currentLevel].classList.remove('active');
    document
      .querySelectorAll('.game-button-holder button')
      [level].classList.add('active');
  }
  function runGame(jsonData) {
    setCurrentLevelJson(jsonData);
    runGameObj.loadLevel(jsonData);
    if (gameInfoObj.Levels[currentLevel - 1].DemoLevel) {
      if (gameInfo.options.structure === 'playgroundEnvironment') {
        runGameObj.setOnFinishedLoading(() => {
          runGameObj.loadCode(jsonData.solution.toString());
        });
      } else {
        runGameObj.loadCode(jsonData.solution);
      }
    } else if (props.statusStorage.game_levels[currentLevel - 1].code) {
      if (gameInfo.options.structure === 'playgroundEnvironment') {
        runGameObj.setOnFinishedLoading(() => {
          const loadedcode = props.statusStorage.game_levels[currentLevel - 1].code;
          runGameObj.loadCode(loadedcode.toString());
        });
      } else {
        const loadedcode = props.statusStorage.game_levels[currentLevel - 1].code;
        runGameObj.loadCode(loadedcode.toString());
      }
    } else if (gameInfo.options.structure === 'playgroundEnvironment') {
      runGameObj.setOnFinishedLoading(() => {
        // runGameObj.loadCode('');
      });
    } else if (jsonData.hasOwnProperty('carryForwardCode') && jsonData.carryForwardCode && (props.statusStorage.game_levels[currentLevel - 2]?.status === 'completed' || jsonData.statusToCarryForwardCode === props.statusStorage.game_levels[currentLevel - 2].status)) {
      const previousLevelCode = props.statusStorage.game_levels[currentLevel - 2].code;
      const codeToLoad = carryForwardPreviousLevelCode(previousLevelCode, runGameObj.fetchCode());
      runGameObj.loadCode(codeToLoad.toString());
    }
  }
  function runCode() {
    const statuses = {...props.statusStorage}
    const levelsData = [...props.statusStorage.game_levels]
    let blocksUsed;
    if (gameInfo.options.structure === 'codingEnvironment') blocksUsed = runGameObj.getBlocksCount();
    runGameObj.setFailure((errorCode) => {
      for (const item of gameInfo.errorMessages) {
        if (item.Code === errorCode) {
          setErrorMsg(item.Message);
          if (!props.teacher || (props.teacher && props.teacherView)) setShowError(true);
          break;
        }
      }
      window.removeEventListener('keydown', arrow_keys_handler, false);
    });
    runGameObj.setSuccess(() => {
      if (gameInfo.options.structure === 'codingEnvironment' && currentLevelJson.hasOwnProperty('maxBlocks') && currentLevelJson.maxBlocks && currentLevelJson.maxBlocks < blocksUsed) {
        setErrorMsg('You found the solution but you have used more blocks than expected');
        if (!props.teacher || (props.teacher && props.teacherView)) setShowError(true);
        window.removeEventListener('keydown', arrow_keys_handler, false);
      } else {
        if (levelsData[currentLevel - 1]?.status !== 'completed') {
          statuses['exercise_game_levels_completed']++;
          statuses['total_game_levels_completed']++;
          statuses.game_levels = levelsData
          levelsData[currentLevel - 1].status = 'completed';
          props.updateStatusStorage(statuses)
        }
        if (levelsData[currentLevel - 1].feedback === 'unavailable') setShowFeedback(true);
        else setShowFeedback(false);
        const x = gameInfo.successMessages.length;
        if (x > 0) {
          const msg = gameInfo.successMessages[Math.floor(Math.random() * x)];
          if (msg === '') {
            setSucessMsg(gameInfo.successMessages[0]);
          } else {
            setSucessMsg(msg);
          }
        } else {
          setSucessMsg('');
        }
        if (!props.teacher || (props.teacher && props.teacherView)) setShowSucess(true);
        if (
          (!props.teacher || (props.teacher && props.teacherView)) &&
          !(statuses?.game_status === "completed")
        ) {
          updateLevelColors(statuses);
        }
        window.removeEventListener('keydown', arrow_keys_handler, false);
      }
    });
    if (props.statusStorage.game_levels[currentLevel - 1]?.status !== 'completed') {
      levelsData[currentLevel - 1].attempts += 1;
    }
    levelsData[currentLevel - 1].code = runGameObj.fetchCode();
    runGameObj.runCode();
    statuses.game_levels = levelsData;
    if(levelsData[currentLevel - 1]?.status!=="completed") props.updateStatusStorage(statuses);
  }
  function updateLevelColors(statuses = {...props.statusStorage}) {
    const buttons = document.querySelectorAll('.game-button-holder button');
    let allComplete = true;
    for (let i = 0; i < buttons.length; i++) {
      if (statuses.game_levels[i]?.status === "completed")
        buttons[i].style.backgroundColor = "#25A596";
      else {
        allComplete = false;
        if (statuses.game_levels[i]?.status === "ongoing")
          buttons[i].style.backgroundColor = "#AECB2A";
        else buttons[i].style.backgroundColor = "#EFEFEF";
      }
    }
    if (allComplete && statuses?.game_status !== "completed") {
      statuses["game_status"] = "completed";
      props.updateStatusStorage(statuses);
    } else if(statuses?.game_status !== "ongoing" && statuses?.game_status !== "completed") {
      statuses["game_status"] = "ongoing";
      props.updateStatusStorage(statuses);
    }
  }
  const onResizeWindow = () => {
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
  }
  function loadLevel(level) {
    window.removeEventListener('resize', onResizeWindow);
    calculateHeight(gameInstructionRef, setInstructionBoxHeight);
    // Recalculate height on window resize
    window.addEventListener('resize', onResizeWindow);
    document
      .querySelectorAll('.game-button-holder button')
      [previousLevel - 1].classList.remove('active');
    document
      .querySelectorAll('.game-button-holder button')
      [level - 1].classList.add('active');
    const statuses = {...props.statusStorage};
    if (props.statusStorage?.game_status !== "completed" && statuses.game_levels[level - 1]?.status !== "ongoing") {
      for (let i = 0; i < statuses.game_levels.length; i++) {
        if (statuses.game_levels[i]?.status !== 'completed') {
          statuses.game_levels[i].status = 'incomplete';
        }
      }
      if (statuses.game_levels[level - 1]?.status !== 'completed') {
        statuses.game_levels[level - 1].status = 'ongoing';
        props.updateStatusStorage(statuses);
      }
    }
    setShowError(false);
    setShowSucess(false);
    setCodingGameSubmitButtonText("Run Code");
    runGame(levelsJson[level - 1]);
    if (!props.teacher || (props.teacher && props.teacherView)) updateLevelColors(statuses);
    setPreviousLevel(currentLevel);
    window.removeEventListener('keydown', arrow_keys_handler, false);
  }
  function handleItemLikedFeedback() {
    const gameLevels = [...props.statusStorage.game_levels];
    gameLevels[currentLevel - 1].feedback = 1;
    props.updateStatusStorage({'game_levels': gameLevels});
    setShowFeedback(false);
  }
  function handleItemDislikedFeedback() {
    const gameLevels = [...props.statusStorage.game_levels];
    gameLevels[currentLevel - 1].feedback = 0;
    props.updateStatusStorage({ 'game_levels': gameLevels });
    setShowFeedback(false);
  }
  function handleRunRestartButtonClick() {
    if (codingGameSubmitButtonText === 'Run Code') {
      window.addEventListener('keydown', arrow_keys_handler, false);
      runCode();
      setCodingGameSubmitButtonText('Reset');
    } else {
      window.removeEventListener('keydown', arrow_keys_handler, false);
      runGameObj.restart();
      setCodingGameSubmitButtonText('Run Code');
    }
  }

  let gameLevelButtonLabel = 1;
  return (
    <>
      {!isLoading ? (
        <div
          id="game-view"
          className="content-tab d-hidden"
          style={props.currentSection === 'game' ? {} : { display: 'none' }}
        >
          {gameInfo.options.structure === 'playgroundEnvironment' ? (
            <>
              <div className="game-button-holder">
                {gameInfo.levels.map((lvl, index) => {
                  if (index === 0) {
                    return (
                      <>
                        {gameInfoObj.Levels[index].DemoLevel ? (
                          <>
                            <BootstrapTooltip placement="bottom" title="Demo Level">
                              <button
                                className="demo-level-btn"
                                key={`btn-${index}`}
                                onClick={() => {
                                  updateActiveClass(index, currentLevel - 1);
                                  setCurrentLevel(index + 1);
                                }}
                              />
                            </BootstrapTooltip>
                            <img key={`img-${index}`} src={HorizontalBar} />
                          </>
                        ) : (
                          <>
                            <button
                              key={`btn-${index}`}
                              onClick={() => {
                                updateActiveClass(index, currentLevel - 1);
                                setCurrentLevel(index + 1);
                              }}
                            >
                              {gameLevelButtonLabel++}
                            </button>
                            <img key={`img-${index}`} src={HorizontalBar} />
                          </>
                        )}
                      </>
                    );
                  }
                  if (index === gameInfo.levels.length - 1) {
                    return (
                      <>
                        {gameInfoObj.Levels[index].DemoLevel ? (
                          <>
                            <img key={`img-${index}`} src={HorizontalBar} />
                            <BootstrapTooltip placement="bottom" title="Demo Level">
                              <button
                                className="demo-level-btn"
                                key={`btn-${index}`}
                                onClick={() => {
                                  updateActiveClass(index, currentLevel - 1);
                                  setCurrentLevel(index + 1);
                                }}
                              />
                            </BootstrapTooltip>
                          </>
                        ) : (
                          <>
                            <img key={`img-${index}`} src={HorizontalBar} />
                            <button
                              key={`btn-${index}`}
                              onClick={() => {
                                updateActiveClass(index, currentLevel - 1);
                                setCurrentLevel(index + 1);
                              }}
                            >
                              {gameLevelButtonLabel++}
                            </button>
                          </>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      {gameInfoObj.Levels[index].DemoLevel ? (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} />
                          <BootstrapTooltip placement="bottom" title="Demo Level">
                            <button
                              className="demo-level-btn"
                              key={`btn-${index}`}
                              onClick={() => {
                                updateActiveClass(index, currentLevel - 1);
                                setCurrentLevel(index + 1);
                              }}
                            />
                          </BootstrapTooltip>
                          <img key={`img-${index}`} src={HorizontalBar} />
                        </>
                      ) : (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} />
                          <button
                            key={`btn-${index}`}
                            onClick={() => {
                              updateActiveClass(index, currentLevel - 1);
                              setCurrentLevel(index + 1);
                            }}
                          >
                            {gameLevelButtonLabel++}
                          </button>
                          <img key={`img-${index}`} src={HorizontalBar} />
                        </>
                      )}
                    </>
                  );
                })}
              </div>
              <div
                className="instructions-box"
                style={{ height: `${intructionBoxHeight}px` }}
              >
                <img src={CheetiRight} alt="" />
                <p id="instructions" ref={gameInstructionRef} >
                  {gameInfo.levels[currentLevel - 1].Instruction}
                </p>
                {gameInfo.videoUrl && (
                  <button
                    className="watch-help-video ml-auto"
                    onClick={() => setHelpVideoPopup(true)}
                  >
                    Demo
                    {' '}
                    <img style={{ width: '2vw', margin: 3 }} src={VideoIcon} />
                  </button>
                )}
              </div>
              <div className="game-content-wrapper">
                <div
                  id="blocklyArea"
                  style={{
                    height: '400px',
                    width: '85vw',
                    marginBottom: '0.9vw',
                  }}
                >
                  <div
                    id="blockly"
                    style={{ height: '30vw', width: '47vw' }}
                  />
                  {' '}
                </div>
                {' '}
              </div>
              <div className="action-buttons-playground">
                <button
                  id="run-code-button"
                  onClick={runCode}
                  disabled={showError || showSuccess}
                  style={{ lineHeight: '2.2vw' }}
                >
                  Submit
                </button>
                <div>
                  {props.teacher && props.teacherView && (
                    <button
                      id="show-solution"
                      style={{ marginRight: '10px', lineHeight: '2.2vw' }}
                      onClick={() => runGameObj.loadCode(currentLevelJson.solution)}
                    >
                      Show Solution
                    </button>
                  )}
                  <button
                    id="game-restart-button"
                    onClick={() => runGameObj.restart()}
                    disabled={showError || showSuccess}
                    style={{ lineHeight: '2.2vw' }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="game-button-holder">
                {gameInfo.levels.map((lvl, index) => {
                  if (codeCarryForwardLevels.includes(index + 1)) return null;
                  const isFirstLevel = index === 0;
                  const isLastLevel = index === gameInfo.levels.length - 1;
                  const isDemoLevel = gameInfoObj.Levels[index].DemoLevel;
                  let connectedLevelCount = 0;
                  let isLevelConnectedToLastLevel = false;
                  for (let i = index + 1; i <= gameInfo.levels.length; i++) {
                    if (codeCarryForwardLevels.includes(i + 1)) {
                      connectedLevelCount++;
                      if (i + 1 === gameInfo.levels.length) isLevelConnectedToLastLevel = true;
                    } else break;
                  }
                  return (
                    <>
                      {isDemoLevel ? (
                        isFirstLevel ? (
                          <>
                            <BootstrapTooltip placement="bottom" title="Demo Level">
                              <button
                                className="demo-level-btn"
                                key={`btn-${index}`}
                                onClick={() => {
                                  updateActiveClass(index, currentLevel - 1);
                                  setCurrentLevel(index + 1);
                                }}
                              />
                            </BootstrapTooltip>
                            <img key={`img-${index}`} src={HorizontalBar} />
                          </>
                        ) : isLastLevel ? (
                          <>
                            <img key={`img-${index}`} src={HorizontalBar} />
                            <BootstrapTooltip placement="bottom" title="Demo Level">
                              <button
                                className="demo-level-btn"
                                key={`btn-${index}`}
                                onClick={() => {
                                  updateActiveClass(index, currentLevel - 1);
                                  setCurrentLevel(index + 1);
                                }}
                              />
                            </BootstrapTooltip>
                          </>
                        ) : (
                          <>
                            <img key={`img-${index}`} src={HorizontalBar} />
                            <BootstrapTooltip placement="bottom" title="Demo Level">
                              <button
                                className="demo-level-btn"
                                key={`btn-${index}`}
                                onClick={() => {
                                  updateActiveClass(index, currentLevel - 1);
                                  setCurrentLevel(index + 1);
                                }}
                              />
                            </BootstrapTooltip>
                            <img key={`img-${index}`} src={HorizontalBar} />
                          </>
                        )
                      ) : isFirstLevel ? (
                        codeCarryForwardLevels.includes(index + 2) ? (
                          <div style={isLevelConnectedToLastLevel ? { paddingLeft: '0.5vw', paddingRight: '0.5vw' } : { paddingLeft: '0.5vw' }}>
                            <button
                              key={`btn-${index}`}
                              onClick={() => {
                                updateActiveClass(index, currentLevel - 1);
                                setCurrentLevel(index + 1);
                              }}
                            >
                              {gameLevelButtonLabel++}
                            </button>
                            <img key={`img-${index}`} src={HorizontalBar} />
                            {Array.from({ length: connectedLevelCount }).map((_, i) => {
                              if (index + i === gameInfo.levels.length - 1) {
                                return (
                                  <>
                                    <img key={`img-${index}`} src={HorizontalBar} />
                                    <button
                                      key={`btn-${index}`}
                                      onClick={() => {
                                        updateActiveClass(index + i + 1, currentLevel - 1);
                                        setCurrentLevel(index + i + 2);
                                      }}
                                    >
                                      {gameLevelButtonLabel++}
                                    </button>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <img key={`img-${index}`} src={HorizontalBar} />
                                  <button
                                    key={`btn-${index}`}
                                    onClick={() => {
                                      updateActiveClass(index + i + 1, currentLevel - 1);
                                      setCurrentLevel(index + i + 2);
                                    }}
                                  >
                                    {gameLevelButtonLabel++}
                                  </button>
                                  <img key={`img-${index}`} src={HorizontalBar} />
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            <button
                              key={`btn-${index}`}
                              onClick={() => {
                                updateActiveClass(index, currentLevel - 1);
                                setCurrentLevel(index + 1);
                              }}
                            >
                              {gameLevelButtonLabel++}
                            </button>
                            <img key={`img-${index}`} src={HorizontalBar} />
                          </>
                        )
                      ) : isLastLevel ? (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} />
                          <button
                            key={`btn-${index}`}
                            onClick={() => {
                              updateActiveClass(index, currentLevel - 1);
                              setCurrentLevel(index + 1);
                            }}
                          >
                            {gameLevelButtonLabel++}
                          </button>
                        </>
                      ) : codeCarryForwardLevels.includes(index + 2) ? (
                        <div style={isLevelConnectedToLastLevel ? { paddingRight: '0.5vw' } : {}}>
                          {Array.from({ length: connectedLevelCount + 1 }).map((_, i) => {
                            if (index + i === gameInfo.levels.length - 1) {
                              return (
                                <>
                                  <img key={`img-${index}`} src={HorizontalBar} />
                                  <button
                                    key={`btn-${index}`}
                                    onClick={() => {
                                      updateActiveClass(index + i, currentLevel - 1);
                                      setCurrentLevel(index + i + 1);
                                    }}
                                  >
                                    {gameLevelButtonLabel++}
                                  </button>
                                </>
                              );
                            }
                            return (
                              <>
                                <img key={`img-${index}`} src={HorizontalBar} />
                                <button
                                  key={`btn-${index}`}
                                  onClick={() => {
                                    updateActiveClass(index + i, currentLevel - 1);
                                    setCurrentLevel(index + i + 1);
                                  }}
                                >
                                  {gameLevelButtonLabel++}
                                </button>
                                <img key={`img-${index}`} src={HorizontalBar} />
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <>
                          <img key={`img-${index}`} src={HorizontalBar} />
                          <button
                            key={`btn-${index}`}
                            onClick={() => {
                              updateActiveClass(index, currentLevel - 1);
                              setCurrentLevel(index + 1);
                            }}
                          >
                            {gameLevelButtonLabel++}
                          </button>
                          <img key={`img-${index}`} src={HorizontalBar} />
                        </>
                      )}
                    </>
                  );
                })}
              </div>
              <div
                className="instructions-box"
                style={{ height: `${intructionBoxHeight}px` }}
              >
                <img src={CheetiRight} alt="" />
                <p id="instructions" ref={gameInstructionRef}>
                  {gameInfo.levels[currentLevel - 1].Instruction}
                </p>
                {gameInfo.videoUrl && (
                  <button
                    className="watch-help-video ml-auto"
                    onClick={() => setHelpVideoPopup(true)}
                  >
                    Demo
                    {' '}
                    <img style={{ width: '2vw', margin: 3 }} src={VideoIcon} />
                  </button>
                )}
              </div>
              <div className="game-content-wrapper">
                <div id="canvas" />
                {' '}
                <div
                  id="blocklyArea"
                  style={{ height: '400px', width: 'calc(85vw - 400px)' }}
                >
                  <div
                    id="blockly"
                    style={{ height: '30vw', width: '47vw' }}
                  />
                  {currentLevelJson && currentLevelJson.hasOwnProperty('maxBlocks') && currentLevelJson.maxBlocks && (
                  <div ref={blocksCountContainerRef} id="game-blocks-count">
                    Blocks Used :&nbsp;
                    <span ref={blocksCountRef}>0</span>
&nbsp;/&nbsp;
                    {currentLevelJson.maxBlocks}
                  </div>
                  )}
                </div>
              </div>
              <div className="button-container d-flex mt-1">
                <div className="action-buttons">
                  <button
                    id="run-restart-button"
                    onClick={handleRunRestartButtonClick}
                    disabled={showError || showSuccess}
                    style={
                      codingGameSubmitButtonText === 'Reset'
                        ? { backgroundColor: '#FFB017', lineHeight: '2.2vw' }
                        : { lineHeight: '2.2vw' }
                    }
                  >
                    {codingGameSubmitButtonText}
                  </button>
                  {gameInfo.arrowButtons && (
                    <>
                      <button
                        id="game-up"
                        onClick={() => {
                          runGameObj.keyClicked('up');
                        }}
                      >
                        <img src={Arrow_up_white} />
                      </button>
                      <button
                        id="game-down"
                        onClick={() => {
                          runGameObj.keyClicked('down');
                        }}
                      >
                        <img src={Arrow_down_white} />
                      </button>
                      <button
                        id="game-left"
                        onClick={() => {
                          runGameObj.keyClicked('left');
                        }}
                      >
                        <img src={Arrow_left_white} />
                      </button>
                      <button
                        id="game-right"
                        onClick={() => {
                          runGameObj.keyClicked('right');
                        }}
                      >
                        <img src={Arrow_right_white} />
                      </button>
                    </>
                  )}
                </div>
                {props.teacher && props.teacherView && (
                  <div
                    className="show-game-solution-btn"
                    style={gameInfo.arrowButtons ? { height: '3.8vw' } : {}}
                  >
                    <button
                      id="show-solution"
                      className="d-flex align-item align-items-center justify-content-center"
                      style={{
                        margin: '0px',
                        fontSize: '1.5vw',
                        lineHeight: '2.2vw',
                      }}
                      onClick={() => runGameObj.loadCode(currentLevelJson.solution)}
                    >
                      Show Solution
                    </button>
                  </div>
                )}
              </div>
              <xml id="toolbox" style={{ display: 'none' }} />
            </>
          )}
          {showError && (
            <ErrorBox
              showError={showError}
              errorMsg={errorMsg}
              currentItem={"game"}
              closeErrorBoxHandler={() => {
                setShowError(false);
              }}
            />
          )}
          {showSuccess && (<GoodJobFeedbackBox
            visibility={showSuccess}
            setVisibility={setShowSucess}
            sucessMsg={sucessMsg}
            showFeedback={showFeedback}
            handleItemLikedFeedback={handleItemLikedFeedback}
            handleItemDislikedFeedback={handleItemDislikedFeedback}
            goToNext={props.goToNext}
            Finish={currentLevel === gameInfo.levels.length}
            currentItem={"game"}
            handleCurrentSectionChange={props.handleCurrentSectionChange}
            getNext={props.getNext}
            nextBtnOnClickhandler={() => {
              setShowSucess(false);
              if(currentLevel === gameInfo.levels.length) return
              setCurrentLevel(currentLevel + 1);
            }}
          />
          )}
          <PlayVideo
            setHelpVideoPopup={setHelpVideoPopup} 
            videoUrl={gameInfo.videoUrl}
            helpVideoPopup={helpVideoPopup}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
