import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { userLogout } from "../src/app/store";
import "./App.css";
import { SetAuthUser } from "./Components/Contexts/AuthUser";
import { SetFilter } from "./Components/Contexts/filterContext";
import { Layout } from "./Components/Layout/Layout";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Dashboard from "./Pages/Dashboards/Dashboard";
import StudentModulePage from "./Pages/Module/StudentModulePage";
import AddSchool from "./Pages/Schools/AddSchool";
import EditSchoolDetails from "./Pages/Schools/EditSchoolDetails";
import SchoolDetails from "./Pages/Schools/SchoolDetails";
import SchoolList from "./Pages/Schools/SchoolList";
import AddStudent from "./Pages/Students/AddStudent";
import StudentDetails from "./Pages/Students/StudentDetails";
import StudentList from "./Pages/Students/StudentList";
import StudentHome from "./Pages/Students/StudentHome";
import AddTeacher from "./Pages/Teachers/AddTeacher";
import TeacherDetails from "./Pages/Teachers/TeacherDetails";
import TeacherList from "./Pages/Teachers/TeacherList";
import Modules from "./Pages/CurriculumMap/Module";
import PuzzleMap from "./Pages/CurriculumMap/PuzzleMap";
import CurriculumMap from "./Pages/CurriculumMap/Curriculum";
import Notebook from "./Pages/Students/Notebook";
import { NotebookContextProvider } from "./Components/Contexts/NotebookContext";
import PlaygroundPage from "./Pages/Students/PlaygroundPage";
import TeacherViewCommunity from "./Pages/Teachers/TeacherViewCommunity";
import TeacherDashboard from "./Pages/Teachers/TeacherDashboard";
import TeacherClasses from "./Pages/Teachers/TeacherClasses";
import LoginPage from "./Pages/Login/LoginPage";
import { useDispatch } from "react-redux";
import Notes from "./Components/ModuleComponents/Notes";
import ResetPassword from "./Pages/Reset/ResetPassword";
import NotFound from "./Pages/Errors/NotFound";
import StudentRollOverMapping from "./Pages/Students/StudentRollOverMapping";
import FetchInterceptor from "./Components/Interceptor/FetchInterceptor";
import PlaygroundPython from "./Pages/Students/PlaygroundPython";

function App() {
  const idleTimer = useRef(null);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const handleOnIdle = () => {
    if (!user.is_authenticated) return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, requestOptions)
      .then((res) => res.json)
      .then((data) => {
        if (data.user) {
          setUser(data.user);
        }
        const notesHandleWindowName = localStorage.getItem("notesHandleName");
        if (notesHandleWindowName) {
          const notesWindow = window.open("", notesHandleWindowName);
          if (notesWindow && !notesWindow?.closed) {
            notesWindow.close();
          }
        }
        dispatch({ type: userLogout });
      })
      .catch((error) => {
        setUser({ user: { is_authenticated: false } });
      })
      .finally(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/");
      });
  };
  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={30 * 60 * 1000}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <FetchInterceptor idleTimer={idleTimer} user={user} />
      <Router>
        <SetAuthUser user={user} setUser={setUser}>
          {!user || !user.is_authenticated ? (
            <Routes>
              <Route exact path="/landing" element={<LoginPage />} />
              <Route exact path="/" element={<LoginPage />} />
              <Route exact path="/reset/:token" element={<ResetPassword />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          ) : (
            <SetFilter>
              <Layout>
                <Routes>
                  <Route exact path="/" element={<ProtectedRoute />} />
                  <Route exact path="/landing" element={<ProtectedRoute />} />
                  <Route exact path="/admin" element={<ProtectedRoute />} />
                  <Route
                    exact
                    path="/school/details"
                    element={<SchoolDetails />}
                  />
                  <Route
                    path="/edit/school/details"
                    element={<EditSchoolDetails />}
                  />
                  <Route exact path="/teacher/list" element={<TeacherList />} />
                  <Route exact path="/teacher/add" element={<AddTeacher />} />
                  <Route exact path="/teacher/edit" element={<AddTeacher />} />
                  <Route
                    exact
                    path="/teacher/details"
                    element={<TeacherDetails />}
                  />
                  <Route exact path="/student/list" element={<StudentList />} />
                  <Route exact path="/student/add" element={<AddStudent />} />
                  <Route exact path="/student/edit" element={<AddStudent />} />
                  <Route
                    exact
                    path="/student/details"
                    element={<StudentDetails />}
                  />
                  <Route
                    exact
                    path="/admin/dashboard"
                    element={<Dashboard />}
                  />
                  <Route
                    exact
                    path="/dashboard/:grade/:section"
                    element={<TeacherDashboard />}
                  />
                  <Route exact path="/school/list" element={<SchoolList />} />
                  <Route
                    exact
                    path="/school/module/map"
                    element={<Modules />}
                  />
                  <Route
                    exact
                    path="/school/puzzle/map"
                    element={<PuzzleMap />}
                  />
                  <Route
                    exact
                    path="/school/curriculum/map"
                    element={<CurriculumMap />}
                  />
                  <Route exact path="/school/add" element={<AddSchool />} />
                  <Route exact path="/home" element={<StudentHome />} />
                  <Route
                    exact
                    path="/modules/:module_id"
                    element={<StudentModulePage />}
                  />
                  <Route
                    exact
                    path="/moduleview/:module_id"
                    element={<StudentModulePage />}
                  />
                  <Route
                    exact
                    path="/notebook"
                    element={
                      <NotebookContextProvider>
                        <Notebook />
                      </NotebookContextProvider>
                    }
                  />
                  <Route
                    exact
                    path="/playground"
                    element={<PlaygroundPage />}
                  />
                  <Route
                    exact
                    path="/playground/view"
                    element={<PlaygroundPage />}
                  />
                  <Route
                    exact
                    path="/community/:grade"
                    element={<TeacherViewCommunity />}
                  />
                  <Route
                    exact
                    path="/notebookview/:id"
                    element={
                      <NotebookContextProvider>
                        <Notebook />
                      </NotebookContextProvider>
                    }
                  />
                  <Route
                    exact
                    path="/notebook/:id"
                    element={
                      <NotebookContextProvider>
                        <Notebook />
                      </NotebookContextProvider>
                    }
                  />
                  <Route exact path="/classes" element={<TeacherClasses />} />
                  <Route
                    exact
                    path="/notes/:grade/:section/:type/:moduleNo/:moduleId"
                    element={<Notes />}
                  />
                  <Route
                    exact
                    path="/reset/:token"
                    element={<ResetPassword />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    exact
                    path="/school/rollover"
                    element={<AddSchool />}
                  />
                  <Route
                    exact
                    path="/student/rollover"
                    element={<StudentRollOverMapping />}
                  />
                  <Route
                    exact
                    path="/playground/python"
                    element={<PlaygroundPython />}
                  />
                  <Route
                    exact
                    path="/playground/python/view"
                    element={<PlaygroundPython />}
                  />
                </Routes>
              </Layout>
            </SetFilter>
          )}
        </SetAuthUser>
      </Router>
    </>
  );
}

export default App;
