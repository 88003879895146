import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { useLocation } from "react-router-dom";
import ModuleSideBar from "../../Components/NavBars/ModuleSideBar";
import clickSound from "../../Assets/sounds/click_sound.mp3";
import Story from "../../Components/ModuleComponents/Story";
import Game from "../../Components/ModuleComponents/Game";
import Python from "../../Components/ModuleComponents/Python";
import Puzzle from "../../Components/ModuleComponents/Puzzle";
import Assesment from "../../Components/ModuleComponents/Assesment";
import InformatonBar from "../../Components/NavBars/InformatonBar";
import Loader from "../../Components/Loader/Loader"
import "../../Styles/Global.css";
import "../../Styles/Module.css";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";

export default function StudentModulePage(props) {
  const { module_id } = useParams();
  const {user} = useContext(authUserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [statusStorage, setStatusStorage] = useState({
    moduleID: module_id,
  });
  const [statusesSet, setStatusesSet] = useState(false);
  const [structure, setStructure] = useState([]);
  const [moduleName, setModuleName] = useState();
  const url = new URL(document.URL);
  const [initialItem, setInitial] = useState(
    document.URL.includes('initial')
      ? url.searchParams.get('initial')
      : 'ongoing',
  );
  const [currentSection, setCurrentSection] = useState(
    document.URL.includes('initial') ? url.searchParams.get('initial') : null,
  );
  const [strapiData, setStrapiData] = useState();
  const [studentName, setStudentName] = useState();
  const { state } = useLocation();

  const [userId, setUserId] = useState(state?.userId ? state.userId : null);
  const [grade, setGrade] = useState(state?.grade ? state.grade : null);
  const [className, setClassName] = useState(state?.class ? state.class : null);
  const [moduleNo, setModuleNo] = useState(
    state?.moduleNo ? state.moduleNo : null
  );
  const [teacherView, setTeacherView] = useState(
    state?.view ? state.view : null
  );
  const { setIsModulePage, setIsTeacherDashboard, setNavbarModuleData, setTeacherGrade,setTeacherSection} = useContext(PrimaryNavBarContext)
  
  useEffect(() => {
    setIsTeacherDashboard(false);
    setIsModulePage(true)
    setTeacherGrade(grade)
    setTeacherSection(className)
    fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}/modules/${module_id}`)
      .then((res) => res.json())
      .then((data) => {
        setStrapiData(data);
        if (user.role.includes("teacher") && teacherView) {
          generateTeacherStatisticsAndStatus(data);
        }
      });
  }, []);
  useEffect(() => {
    if(strapiData){
    const isTeacher = user.role.includes("teacher");
      if (!isTeacher || (isTeacher && !teacherView)) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: null,
      };
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/modules/${module_id}`;
      if (user.role.includes('teacher')) {
        requestOptions.body = JSON.stringify({ studentId: userId });
      } 
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setModuleName(data.name);
          setStructure(data.structure);
          if (data.student_name) setStudentName(data.student_name);
          const statusStorageTemp = {};
          for (const key in data.statuses) {
            statusStorageTemp[key] = data.statuses[key];
          }
          if(Object.keys(statusStorageTemp).length === 0)  { 
            generateTeacherStatisticsAndStatus(strapiData);
          }
          else {
            statusStorageTemp['moduleID'] = module_id;
            setStatusStorage(statusStorageTemp);
          }
          setStatusesSet(true);
        });
    }
  }
},[strapiData])
  
  useEffect(() => {
    if (structure.length && statusStorage) {
      const statusStorageNew = { ...statusStorage };
      let isDataUpdated = false
      for (const structureItem of structure) {
        if (
          statusStorage[`${structureItem + "_status"}`] === 'ongoing'
          && initialItem !== structureItem && initialItem !== 'ongoing'
        ) {
          if (!user.role.includes('teacher')) {
            statusStorageNew[`${structureItem + "_status"}`] = 'incomplete';
            isDataUpdated = true
          }
        }
      }
      if (statusStorage[`${currentSection + "_status"}`] === 'incomplete') {
        if (!user.role.includes('teacher')) {
          statusStorageNew[`${currentSection + "_status"}`] = 'ongoing';
          isDataUpdated = true
        }
      }
      if (initialItem === 'ongoing') {
        let found = false;
        for (let i = 0; i < structure.length; i++) {
          if (!statusStorage.hasOwnProperty(`${structure[i] + "_status"}`)) continue;
          if (statusStorage[`${structure[i] + "_status"}`] === 'ongoing') {
            setCurrentSection(structure[i]);
            found = true;
            break;
          }
        }
        if (!found) {
          let sec_id = 0;
          for (let i = 0; i < structure.length; i++) {
            if (statusStorage[`${structure[i] + "_status"}`] === 'incomplete') {
              sec_id = i;
              if (!user.role.includes('teacher')) {
                statusStorageNew[`${structure[i] + "_status"}`] = 'ongoing';
                isDataUpdated = true
              }
              break;
            }
          }
          setCurrentSection(structure[sec_id]);
        }
      }
      if(isDataUpdated) setStatusStorage(statusStorageNew);
      setIsLoading(false);
    }
  }, [statusesSet]);

  useEffect(() => {
    if (currentSection && strapiData && moduleName) {
      setNavbarModuleData({moduleName : moduleName, currentSection : currentSection})
    }
  }, [currentSection,strapiData,moduleName]);

  useEffect(() => {
    updateItems();
  }, [statusStorage]);
  
  function generateTeacherStatisticsAndStatus(strapiData) {
    setModuleName(strapiData["Module Name"]);
    setGrade(grade);
    const structure = [];
    const status = {
      status: "ongoing",
      module_name: strapiData["Module Name"],
    };
    const items = [
      "Story",
      "Game",
      "Python",
      "Puzzle",
      "Assessment",
      "Project",
    ];
    let firstItem = true;
    for (let item of items) {
      if (strapiData[item]?.length) {
        switch (item) {
          case "Story":
            structure.push("story");
            if(teacherView && firstItem){
            status["story_status"] = "ongoing";
            firstItem = false;
          }
            else status["story_status"] = "incomplete";
            break;
          case "Game":
            structure.push("game");
            status["game_levels"] = [];
            if(teacherView && firstItem){
              status["game_status"] = "ongoing";
              firstItem = false;
            }
            else status["game_status"] = "incomplete";
            break;
          case "Python":
            structure.push("python");
            status["python_levels"] = [];
            if(teacherView && firstItem){
              status["python_status"] = "ongoing";
              firstItem = false;
            }
            else status["python_status"] = "incomplete";
            break;
          case "Puzzle":
            structure.push("puzzle");
            status["puzzle_levels"] = [];
            if(teacherView && firstItem){
              status["puzzle_status"] = "ongoing";
              firstItem = false;
            }
            else status["puzzle_status"] = "incomplete";
            break;
          default:
            break;
        }
      } else {
        if (item === "Assessment") {
          if (
            strapiData[item]["Game Set"]?.length ||
            strapiData[item]["Puzzle"]?.length || 
            strapiData[item]["Python"]?.length
          ) {
            structure.push("assessment");
            status["assessment_levels"] = [];
            if(teacherView)
            {
              status["assessment_state"] = "finished";
              status["assessment_status"] = "completed";
            }
            else {
              status["assessment_state"] = "notstarted";
              status["assessment_status"] = "incomplete";
            }
          }
        }
      }
    }
    const structureItems = [
      "story",
      "game",
      "python",
      "puzzle",
      "activity",
      "assessment",
    ];
    for (let item of structureItems) {
      if (item in structure) {
        status[`${item}_status`] = "ongoing";
        break;
      }
    }
    setStructure(structure);
    setStatusStorage(status);
    setStatusesSet(true);
  }
  function handleCurrentSectionChange(newSection, immediate = false) {
    if (newSection !== currentSection) {
      const status = {...statusStorage};
      if (immediate) {
        const audio = new Audio(clickSound);
        audio.volume = 0.3;
        audio.play();
      }
      if (
        !user.role.includes('teacher')
        || (user.role.includes('teacher') && teacherView)
      ) {
        if (
          statusStorage[`${currentSection + '_status' }`] === 'ongoing'
          && statusStorage[`${newSection + '_status' }`] === 'incomplete'
        ) {
          status[`${newSection + '_status' }`] = 'ongoing';
          status[`${currentSection + '_status' }`] = 'incomplete';
        } else if (statusStorage[`${currentSection + '_status' }`] === 'ongoing') {
          status[`${currentSection + '_status' }`] = 'incomplete';
        } else if (statusStorage[`${newSection + '_status' }`] === 'incomplete') {
          status[`${newSection + '_status' }`] = 'ongoing';
        }
        setStatusStorage(status);
      }
      setCurrentSection(newSection);
    }
  }
  function updateItems() {
    if (statusesSet) {
      if (user.role.includes('teacher') && !document.URL.includes('view')) return;
      const items = ['story', 'game', 'python', 'puzzle', 'assessment', 'project'];
      let allComplete = true;
      for (let i = 0; i < items.length; i++) {
        if (!statusStorage.hasOwnProperty(`${items[i] + '_status'}`)) continue;
        if (statusStorage[`${items[i] + '_status'}`] !== 'completed') {
          allComplete = false;
          break;
        }
      }
      if (allComplete) statusStorage.status = 'completed';
      updateToCloud();
    }
  }
  function updateToCloud() {
    if (user.role.includes('teacher')) return;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status-upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(statusStorage),
    }).then((response) => {
      if (response.redirected === true) {
        document.location = '/landing';
      }
    });
  }
  function updateStatusStorage(updateStatuses,item = null) {
    if (
      !user.role.includes('teacher')
      || (user.role.includes('teacher') && teacherView)
    ) {
        setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
    else if(item === 'puzzle_levels' && statusStorage.puzzle_levels.length === 0) {
      setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
    else if(item === 'assessment_levels' && statusStorage.assessment_levels.length === 0) {
      setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
    else if(item === 'game_levels' && statusStorage.game_levels.length === 0) {
      setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
    else if(item === 'project_levels' && statusStorage.project_levels.length === 0) {
      setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
    else if(item === 'python_levels' && statusStorage.python_levels.length === 0) {
      setStatusStorage({ ...statusStorage, ...updateStatuses});
    }
  }
  function getNext(type) {
    let name;
    for (let i = 0; i < structure.length; i++) {
      if (structure[i] === type) {
        if (i === structure.length) name = structure[0];
        else {
          name = structure[i + 1];
        }
      }
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  
  return (
    <>
        {!isLoading && strapiData ? (
    <>
          <div className='student-module-page'>
            <ModuleSideBar
              backLink={`/home?module=${module_id}&type=module`}
              statusStorage={statusStorage}
              structure={structure}
              handleCurrentSectionChange={handleCurrentSectionChange}
              currentSection={currentSection}
              teacher={user.role.includes('teacher')}
              userId={userId}
              moduleID={module_id}
              grade={grade}
              className={className}
              teacherView={teacherView}
              moduleNo={moduleNo}
              state={state}
            />
            <div style={{ marginTop: '8vh', marginLeft: '10vw' }}>
              {user.role.includes('teacher') && !teacherView && 
              (
                <InformatonBar
                  textToDisplay={`${studentName}`}
                />
              )
              }
              <Story
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes('teacher')}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === 'story' ? false: true}
              />
              <Game
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes('teacher')}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === 'game' ? false: true}
              />
              <Puzzle
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes('teacher')}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === 'puzzle' ? false: true}
              />
              <Assesment
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes('teacher')}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === 'assessment' ? false: true}
              />
              {structure.includes('python') && <Python
                strapiData={strapiData}
                statusStorage={statusStorage}
                updateStatusStorage={updateStatusStorage}
                getNext={getNext}
                handleCurrentSectionChange={handleCurrentSectionChange}
                currentSection={currentSection}
                teacher={user.role.includes("teacher")}
                teacherView={teacherView}
                goToNext={structure.slice(-1)[0] === 'python' ? false: true}
              />}
              {currentSection === "project" && <h2>Project</h2>}
            </div>
          </div>
    </>    
      ) : (
       <Loader/>
      )}
    </>
  );
}
