import React, { useEffect, useRef } from "react";
import CheetiRight from "../../../Assets/Images/CheetiRight.svg";
import Arrow_up_white from "../../../Assets/Images/Arrow_up_white.svg";
import Arrow_down_white from "../../../Assets/Images/Arrow_down_white.svg";
import Arrow_left_white from "../../../Assets/Images/Arrow_left_white.svg";
import Arrow_right_white from "../../../Assets/Images/Arrow_right_white.svg";

function AssessmentGame(props) {
  const {
    data,
    currentLevel,
    assesmentGamesetInfo,
    instructionBoxHeight,
    showErrorFeedbackBox,
    showGoodJobFeedbackBox,
    showBadJobFeedbackBox,
    teacherView,
    teacher,
    assesmentRunGameObj,
    currentLevelJson,
    showGiveupButton,
    codingGameSubmitButtonText,
    setCodingGameSubmitButtonText,
    arrow_keys_handler,
    statusStorage,
    setShowErrorFeedbackBox,
    setShowBlocksExceedMsg,
    updateStatusStorage,
    updateLevelColors,
    setShowGiveUpButton,
    setNextButtonText,
    setSucessMsg,
    setShowGoodJobFeedbackBox,
    setShowBadJobFeedbackBox,
  } = props;

  const assesmentBlocksCountRef = useRef(null);
  const assesmentBlocksCountContainerRef = useRef(null);

  useEffect(() => {
    if (currentLevelJson && currentLevelJson.hasOwnProperty("maxBlocks"))
      assesmentRunGameObj.getBlocksCount();
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (assesmentBlocksCountRef.current) {
          if (
            assesmentBlocksCountRef.current.innerText >
            currentLevelJson.maxBlocks
          ) {
            assesmentBlocksCountContainerRef.current.style.border =
              "0.2vw solid #B30A74";
            assesmentBlocksCountContainerRef.current.style.borderTop = "none";
          } else {
            assesmentBlocksCountContainerRef.current.style.border = "none";
          }
        }
      });
    });
    if (assesmentBlocksCountRef.current) {
      observer.observe(assesmentBlocksCountRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [currentLevelJson, assesmentBlocksCountRef]);

  function runCode() {
    setCodingGameSubmitButtonText("Reset");
    let blocksUsedInAssesmentGame;
    const statuses = { ...statusStorage };
    if (assesmentGamesetInfo.options.structure === "codingEnvironment")
      blocksUsedInAssesmentGame = assesmentRunGameObj.getBlocksCount();
    assesmentRunGameObj.setFailure(() => {
      window.removeEventListener("keydown", arrow_keys_handler, false);
      if (!teacher || (teacher && teacherView)) setShowErrorFeedbackBox(true);
    });
    assesmentRunGameObj.setSuccess(() => {
      if (
        assesmentGamesetInfo.options.structure === "codingEnvironment" &&
        currentLevelJson.hasOwnProperty("maxBlocks") &&
        currentLevelJson.maxBlocks &&
        currentLevelJson.maxBlocks < blocksUsedInAssesmentGame
      ) {
        window.removeEventListener("keydown", arrow_keys_handler, false);
        if (!teacher || (teacher && teacherView)) {
          setShowBlocksExceedMsg(true);
          setShowErrorFeedbackBox(true);
        }
      } else {
        if (
          statuses.assessment_levels[currentLevel - 1]?.status !== "completed"
        ) {
          statuses["total_game_levels_completed"]++;
          statuses["assessment_game_levels_completed"]++;
          statuses.assessment_levels[currentLevel - 1].status = "completed";
          updateStatusStorage(statuses);
        }
        updateLevelColors(statuses);
        setShowGiveUpButton(false);
        if (currentLevel === data.numLevels) setNextButtonText("Finish");
        const x = assesmentGamesetInfo.successMessages.length;
        if (x > 0) {
          const msg =
            assesmentGamesetInfo.successMessages[Math.floor(Math.random() * x)];
          if (msg === "") {
            setSucessMsg(assesmentGamesetInfo.successMessages[0]);
          } else {
            setSucessMsg(msg);
          }
        } else {
          setSucessMsg("");
        }
        if (!teacher || (teacher && teacherView))
          setShowGoodJobFeedbackBox(true);
        window.removeEventListener("keydown", arrow_keys_handler, false);
      }
    });
    if (
      statusStorage.assessment_levels[currentLevel - 1]?.status !== "completed"
    ) {
      statuses.assessment_levels[currentLevel - 1].attempts += 1;
      if (statuses.assessment_levels[currentLevel - 1].attempts >= 10)
        setShowGiveUpButton(true);
    }
    const code = assesmentRunGameObj.fetchCode();
    if (code) {
      if (
        (statusStorage.assessment_status !== "completed" &&
          statusStorage.assessment_levels[currentLevel - 1].giveup === 0) ||
        teacherView
      ) {
        statuses.assessment_levels[currentLevel - 1].code = code;
      }
    }
    if (statuses?.assessment_status !== "completed")
      updateStatusStorage(statuses);
    assesmentRunGameObj.runCode();
  }

  function giveupLevel() {
    const statuses = { ...statusStorage };
    assesmentRunGameObj.loadSolution();
    if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
      statuses["total_game_levels_completed"]++;
      statuses["assessment_game_levels_completed"]++;
      statuses.assessment_levels[currentLevel - 1].status = "completed";
      statuses["is_game_level_giveup"] = true;
    }
    updateLevelColors(statuses);
    setShowGiveUpButton(false);
    if (currentLevel === data.numLevels) setNextButtonText("Finish");
    statuses.assessment_levels[currentLevel - 1].giveup = 1;
    updateStatusStorage(statuses);
    setShowBadJobFeedbackBox(true);
  }

  return (
    <div
      id="game-assesment-view"
      style={
        data.levels[currentLevel - 1].type !== "game" ? { display: "none" } : {}
      }
    >
      {assesmentGamesetInfo?.options.structure === "playgroundEnvironment" ? (
        <>
          <div
            className="instructions-box"
            style={{
              height: `${instructionBoxHeight}px`,
            }}
          >
            <img src={CheetiRight} alt="" />
            {data.levels[currentLevel - 1].type === "game" && (
              <p id="assesment-instructions">
                {assesmentGamesetInfo?.levels[currentLevel - 1].Instruction}
              </p>
            )}
          </div>
          <div className="game-content-wrapper">
            <div
              id="assesment-blocklyArea"
              style={{ height: "400px", width: "85vw" }}
            >
              <div
                id="assesment-blockly"
                style={{ height: "30vw", width: "47vw" }}
              />
            </div>
          </div>
          <div
            className="d-flex flex-row"
            style={{ width: "85vw", marginTop: "1vh" }}
          >
            <div className="action-buttons-playground">
              <button
                id="assesment-run-code-button"
                onClick={runCode}
                disabled={
                  showErrorFeedbackBox ||
                  showGoodJobFeedbackBox ||
                  showBadJobFeedbackBox
                }
              >
                Submit
              </button>
              {teacher && teacherView && (
                <button
                  id="show-solution"
                  style={{ lineHeight: "2.2vw" }}
                  onClick={() =>
                    assesmentRunGameObj.loadCode(currentLevelJson.solution)
                  }
                >
                  Show Solution
                </button>
              )}
            </div>
            {!teacher && showGiveupButton && (
              <button
                className="giveup-button ml-auto"
                style={{ width: "12vw" }}
                onClick={giveupLevel}
                disabled={
                  showErrorFeedbackBox ||
                  showGoodJobFeedbackBox ||
                  showBadJobFeedbackBox
                }
              >
                Skip Level
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="button-holder" />
          <div
            className="instructions-box"
            style={{
              height: `${instructionBoxHeight}px`,
            }}
          >
            <img src={CheetiRight} alt="" />
            {data.levels[currentLevel - 1].type === "game" && (
              <p id="assesment-instructions">
                {assesmentGamesetInfo?.levels[currentLevel - 1].Instruction}
              </p>
            )}
          </div>
          <div className="game-content-wrapper">
            <div id="assesment-canvas" />
            <div
              id="assesment-blocklyArea"
              style={{
                height: "400px",
                width: "calc(85vw - 400px)",
              }}
            >
              <div
                id="assesment-blockly"
                style={{ height: "30vw", width: "47vw" }}
              />
              {currentLevelJson &&
                currentLevelJson.hasOwnProperty("maxBlocks") &&
                currentLevelJson?.maxBlocks && (
                  <div
                    ref={assesmentBlocksCountContainerRef}
                    id="assesment-game-blocks-count"
                  >
                    Blocks Used :&nbsp;
                    <span ref={assesmentBlocksCountRef}>0</span>
                    &nbsp;/&nbsp;
                    {currentLevelJson?.maxBlocks}
                  </div>
                )}
            </div>
          </div>
          <div
            className="d-flex flex-row"
            id="game-btn-container"
            style={{ width: "77vw", margin: "auto" }}
          >
            <div className="action-buttons">
              <button
                id="assesment-run-restart-button"
                style={
                  codingGameSubmitButtonText === "Reset"
                    ? {
                        backgroundColor: "#FFB017",
                        lineHeight: "2.2vw",
                      }
                    : { lineHeight: "2.2vw" }
                }
                onClick={
                  codingGameSubmitButtonText === "Run Code"
                    ? () => {
                        window.addEventListener(
                          "keydown",
                          arrow_keys_handler,
                          false
                        );
                        runCode();
                      }
                    : () => {
                        window.removeEventListener(
                          "keydown",
                          arrow_keys_handler,
                          false
                        );
                        assesmentRunGameObj.restart();
                        setCodingGameSubmitButtonText("Run Code");
                      }
                }
                disabled={
                  showErrorFeedbackBox ||
                  showGoodJobFeedbackBox ||
                  showBadJobFeedbackBox
                }
              >
                {codingGameSubmitButtonText}
              </button>
              {assesmentGamesetInfo?.arrowButtons && (
                <>
                  <button
                    id="assesment-up"
                    onClick={() => {
                      assesmentRunGameObj?.keyClicked("up");
                    }}
                  >
                    <img src={Arrow_up_white} />
                  </button>
                  <button
                    id="assesment-down"
                    onClick={() => {
                      assesmentRunGameObj?.keyClicked("down");
                    }}
                  >
                    <img src={Arrow_down_white} />
                  </button>
                  <button
                    id="assesment-left"
                    onClick={() => {
                      assesmentRunGameObj?.keyClicked("left");
                    }}
                  >
                    <img src={Arrow_left_white} />
                  </button>
                  <button
                    id="assesment-right"
                    onClick={() => {
                      assesmentRunGameObj?.keyClicked("right");
                    }}
                  >
                    <img src={Arrow_right_white} />
                  </button>
                </>
              )}
            </div>
            {teacher && teacherView && (
              <div
                className="show-game-solution-btn"
                style={
                  assesmentGamesetInfo?.arrowButtons ? { height: "3.8vw" } : {}
                }
              >
                <button
                  id="show-solution"
                  className="d-flex align-item align-items-center justify-content-center"
                  style={{
                    margin: "0px",
                    fontSize: "1.5vw",
                    lineHeight: "2.2vw",
                  }}
                  onClick={() =>
                    assesmentRunGameObj?.loadCode(currentLevelJson.solution)
                  }
                >
                  Show Solution
                </button>
              </div>
            )}
            {!teacher && showGiveupButton && (
              <button
                className="giveup-button ml-auto"
                disabled={
                  showErrorFeedbackBox ||
                  showGoodJobFeedbackBox ||
                  showBadJobFeedbackBox
                }
                onClick={giveupLevel}
              >
                Skip Level
              </button>
            )}
          </div>
          <xml id="assesment-toolbox" style={{ display: "none" }} />
        </>
      )}
    </div>
  );
}

export default AssessmentGame;
