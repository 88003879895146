import React from "react";
import BigArrowLeft from '../../Assets/Images/BigArrowLeft.svg';
import BigArrowRight from '../../Assets/Images/BigArrowRight.svg';

export default function StoryView(props) {
  return (
    <>
      <div
        className='story-area d-flex justify-content-center align-items-center'
        style={{ padding: "0px 10px" }}
      >
        <img
          className='story-nav-arrow-left'
          style={props.currentPage === 1 ? { visibility: "hidden" } : {}}
          src={BigArrowLeft}
          alt=''
          onClick={props.handleBigArrowLeftClick}
        />
        <div className='main-story-view'>
          <div className='row'>
            <div className='col' style={{ flex: 1 }} />
            <div className="">
                  <strong>
                    <p className="story-title">{props.storyTitle}</p>
                  </strong>
                </div>
            <div className='d-flex col justify-content-end' style={{ flex: 1 }}>
              <p className='story-page-number mr-2'>
                {props.currentPage}/{props.maxPages}
              </p>
              {props.currentPage === props.maxPages &&
                props.showFinishButton && (
                  <button
                    className='story-completed-button'
                    onClick={() => {
                      if (
                        !props.teacher ||
                        (props.teacher && props.teacherView)
                      )
                        props.setFeedbackActive(true);
                      props.setShowFinishButton(false);
                    }}
                  >
                    Next
                  </button>
                )}
            </div>
          </div>
              <img
                className='story-slide'
                src={props.urlList[props.currentPage - 1]}
                alt=''
                style={{ width: "100%", height: "32vw" }}
              />
              <pre className='story-text'>{props.story.Frames[props.currentPage - 1].text}</pre>
        </div>
        <img
          className='story-nav-arrow-right'
          style={props.currentPage === props.maxPages ? { visibility: "hidden" } : {}}
          src={BigArrowRight}
          alt=''
          onClick={props.handleBigArrowRightClick}
        />
      </div>
    </>
  );
}
