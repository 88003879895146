import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  DialogActions,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PlaygroundProjects from "../PlaygroundComponents/PlaygroundProjects";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "fit-content",
    padding: "1rem",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    marginBottom: "3.2vh",
    lineHeight: "5vw",
    fontWeight: "normal",
    marginTop: "1vw",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
    marginBottom: "1rem",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    border: "none",
    borderRadius: "10px",
    padding: "1px 20px",
    fontSize: "1.5vw",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #delete-project-action-buttons": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& #confirm-delete-project": {
    backgroundColor: "#aecb2a !important",
    marginRight: "1vw",
  },
  "& #denied-delete-project": {
    backgroundColor: "#FFB017 !important",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#343a40",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Community(props) {
  const { section, grade } = props;
  document.title = "CSSoch | Playground";
  const [isLoading, setIsLoading] = useState(true);
  const [noCommunity, setNoCommunity] = useState(true);
  const [userProjectsData, setUsersProjectData] = useState([]);
  const [createProjectGameSets, setCreateProjectGameSets] = useState([]);
  const [communityProjectsData, setCommunityProjectsData] = useState([]);
  const [maxProjectLimit, setMaxProjectLimit] = useState();
  const [usersTotalProjects, setUsersTotalProjects] = useState(-1);
  const [canCreateProject, setCanCreateProject] = useState(true);
  const [
    usersTotalProjectsChangedFirstTime,
    setUsersTotalProjectsChangedFirstTime,
  ] = useState(false);
  const [projectDeleteDialogContent, setProjectDeleteDialogContent] =
    useState();
  const [deleteProjectPopup, setDeleteProjectPopup] = useState(false);
  const [playgroundProjectIdList, setPlaygroundProjectIdList] = useState([]);
  const [pythonProjectEnabled, setPythonProjectEnabled] = useState(false);
  const [pythonGraphicsEnabled, setPythonGraphicsEnabled] = useState(false);


  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: null,
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-playground-set`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          return;
        } else setNoCommunity(false);
        setIsLoading(false);
        setCreateProjectGameSets(data["Game Sets"]);
        setPlaygroundProjectIdList((data["Game Sets"]?.map((set) => set.id))?.sort() ?? []);
        setPythonProjectEnabled(data["Python"]);
        setPythonGraphicsEnabled(data["Python Graphics"]);
        setMaxProjectLimit(data["Max Projects Allowed"]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (section && maxProjectLimit) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        body: null,
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-community-projects`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setCommunityProjectsData(data?.projects);
        });
    }
  }, [section, maxProjectLimit]);

  useEffect(() => {
    if (!maxProjectLimit) return;
    if (usersTotalProjectsChangedFirstTime) {
      setUsersTotalProjectsChangedFirstTime(false);
      return;
    } else if (usersTotalProjects === -1)
      setUsersTotalProjectsChangedFirstTime(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: null,
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-student-all-playground-projects`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setUsersProjectData(data?.projects);
        setUsersTotalProjects(data?.projects.length);
        setCanCreateProject(data?.projects.length < maxProjectLimit);
      });
  }, [maxProjectLimit, usersTotalProjects]);

  const deleteProject = (project) => {
    const payload = {
      project_id: project.project_id,
      section: section,
      published: project.published,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/delete-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setUsersTotalProjects((prev) => prev - 1);
        handleDeleteProjectPopupClose();
      });
  };

  const handleDeleteProjectPopupOpen = (project) => {
    setProjectDeleteDialogContent(
      <>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleDeleteProjectPopupClose}
        >
          Delete Project
        </BootstrapDialogTitle>
        <Typography gutterBottom>Do you want to Delete Project?</Typography>
        <DialogActions>
          <div id='delete-project-action-buttons'>
            <Button
              onClick={() => deleteProject(project)}
              id='confirm-delete-project'
            >
              Yes
            </Button>
            <Button
              onClick={handleDeleteProjectPopupClose}
              id='denied-delete-project'
            >
              No
            </Button>
          </div>
        </DialogActions>
      </>
    );
    setDeleteProjectPopup(true);
  };

  const handleDeleteProjectPopupClose = () => {
    setDeleteProjectPopup(false);
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {noCommunity ? (
            <div className='no-community-playground'>
              <p>Projects Coming Soon...</p>
            </div>
          ) : (
            <>
              <Container className='community-container' maxWidth={false}>
                <Grid
                  container
                  spacing={2}
                  className='community-container-content'
                >
                  <Grid item xs={12} className='user-community-project-wrapper'>
                    <Grid item xs={12} sx={{ marginBottom: "2vh" }}>
                      <PlaygroundProjects
                        type='Your Projects'
                        projects={userProjectsData}
                        section={section}
                        maxProjectLimit={maxProjectLimit}
                        grade={grade}
                        canCreateProject={canCreateProject}
                        createProjectSet={createProjectGameSets}
                        handleDeleteProjectPopupOpen={
                          handleDeleteProjectPopupOpen
                        }
                        playgroundProjectIdList={playgroundProjectIdList}
                        pythonProjectEnabled={pythonProjectEnabled}
                        pythonGraphicsEnabled={pythonGraphicsEnabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PlaygroundProjects
                        type='Community Projects'
                        projects={communityProjectsData}
                        section={section}
                        playgroundProjectIdList={playgroundProjectIdList}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
              <BootstrapDialog
                onClose={handleDeleteProjectPopupClose}
                aria-labelledby='customized-dialog-title'
                open={deleteProjectPopup}
              >
                {projectDeleteDialogContent}
              </BootstrapDialog>
            </>
          )}
        </>
      )}
    </div>
  );
}
