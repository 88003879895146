const calculateHeight = (instructionRef,setInstructionBoxHeight) => {
  const boxHeight = 64.6262;
  if (instructionRef.current) {
    let textHeight = instructionRef.current.clientHeight;
    if (boxHeight <= textHeight) {
      textHeight += 20;
      setInstructionBoxHeight(textHeight);
    } else {
      setInstructionBoxHeight(textHeight);
    }
  }
};

export {calculateHeight};