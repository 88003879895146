import { useEffect, useRef, useState } from 'react';
import HorizontalBar from '../../Assets/Images/HorizontalBar.svg';
import GoodJobFeedbackBox from './FeedbackBox/GoodJobFeedbackBox';
import BadJobFeedbackBox from './FeedbackBox/BadJobFeedbackBox';

export default function Puzzle(props) {
  const [isLoadig, setIsLoading] = useState(true);
  const [data, setData] = useState({
    levels: props.strapiData.Puzzle,
    numLevels: props.strapiData.Puzzle.length,
  });
  const [currentLevel, setCurrentLevel] = useState(1);
  const [previousLevel, setPreviousLevel] = useState(1);
  const [resourcesList, setResourecesList] = useState([]);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [levelData, setLevelData] = useState();
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [showBadJobFeedback, setShowBadJobFeedback] = useState(false);
  const [showGoodJobFeedback, setShowGoodJobFeedback] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);
  const [showSolutionButton, setShowSolutionButton] = useState(true);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const explanationRef = useRef(null)
  useEffect(() => {
    if(props.currentSection === 'puzzle') {
      (props.teacher && !props.teacherView) ? document.title = "CSSoch | Review Puzzle" : document.title = "CSSoch | Puzzle";
      if (!isRendredOnce) {
        setupPuzzleStatistics();
        loadResources(data);
      }
    }
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
  }, [props.currentSection]);
  useEffect(() => {
    if (!isLoadig) {
      updateLevelColors();
      loadLevel(currentLevel);
      setIsRenderedOnce(true);
    }
  }, [isLoadig]);

  useEffect(() => {
    if(showPuzzleExplanation) explanationRef.current.scrollIntoView({ behavior: 'smooth' });
  },[showPuzzleExplanation])
  
  function setupPuzzleStatistics() {
    if (!props.statusStorage.puzzle_levels.length) {
      const puzzleLevels= []
      for (let i = 0; i < data.numLevels; i++) {
        puzzleLevels.push({
          feedback: 'unavailable',
          option: 'unavailable',
          result: 'unavailable',
          status:'incomplete'
        });
      }
      props.updateStatusStorage({'puzzle_levels' : puzzleLevels},'puzzle_levels');
    }
  }
  function loadResources(loadedData) {
    const resources = [];
    const { numLevels } = data;
    const options = ['question', 'optionA', 'optionB', 'optionC', 'optionD'];
    const levelsDone = [];
    for (let i = 0; i < numLevels; i++) levelsDone.push(false);
    for (let i = 0; i < numLevels; i++) {
      let Options;
      const optionValues = [];
      Options = loadedData.levels[i].Options;
      if (!Options) {
        Options = [];
      }
      optionValues.push(loadedData.levels[i]['Question Image']);
      for (const item of Object.values(Options)) {
        if (item.includes('https://')) {
          optionValues.push(item);
        } else {
          optionValues.push(item);
        }
      }
      // adding option array to resourceList
      resources.push(optionValues);
      setResourecesList(resources);
      levelsDone[i] = true;
      for (let i = 0; i < levelsDone.length; i++) {
        if (!levelsDone[i]) break;
        if (i === levelsDone.length - 1) {
          setLoaded(true);
          initialize();
        }
      }
    }
  }
  function initialize() {
    let currentLevelTemp = currentLevel;
    for (let i = 0; i < data.numLevels; i++) {
      if (props.statusStorage.puzzle_levels[i]?.status === 'ongoing') {
        currentLevelTemp = i + 1;
        setCurrentLevel(i + 1);
        setPreviousLevel(i + 1);
      }
    }
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    if (currentLevelTemp === 1 && puzzleLevels[0]?.status !== 'ongoing') {
      for (let i = 0; i < puzzleLevels.length; i++) {
        if (puzzleLevels[i]?.status === 'incomplete') {
          puzzleLevels[i].status = 'ongoing';
          props.updateStatusStorage({ 'puzzle_levels': puzzleLevels });
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          break;
        }
      }
    }
    setIsLoading(false);
  }
  function updateLevelColors(statuses={...props.statusStorage}) {
    const buttons = document.querySelectorAll('.puzzle-button-holder > button');
    let allComplete = true;
    for (let i = 0; i < buttons.length; i++) {
      if (statuses.puzzle_levels[i]?.status === 'completed') buttons[i].style.backgroundColor = '#25A596';
      else {
        allComplete = false;
        if (statuses.puzzle_levels[i]?.status === 'ongoing') buttons[i].style.backgroundColor = '#AECB2A';
        else buttons[i].style.backgroundColor = '#EFEFEF';
      }
    }
    if (allComplete && statuses.puzzle_status !== 'completed') {
      statuses['puzzle_status'] = 'completed';
      props.updateStatusStorage(statuses);
    } else if(statuses.puzzle_status === 'incomplete') {
      statuses['puzzle_status'] = 'ongoing';
      props.updateStatusStorage(statuses);
    }
  }
  function loadLevel(level) {
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
    setShowSolutionButton(true);
    if (
      props.statusStorage.puzzle_levels[level - 1].feedback
      === 'unavailable'
    ) setShowFeedback(true);
    else setShowFeedback(false);
    setShowSubmitButton(true);
    setShowPuzzleExplantion(false);
    setAnswerChecked(false);
    setStarted(true);
    if (previousLevel === level && started) return;
    setCurrentlyClicked(-1);
    setDisableSubmitButton(true);
    document
      .querySelectorAll('.puzzle-button-holder > button')
      [previousLevel - 1].classList.remove('active');
    document
      .querySelectorAll('.puzzle-button-holder > button')
      [level - 1].classList.add('active');
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    if (props.statusStorage?.puzzle_status !== "completed" && puzzleLevels[level - 1]?.status !== 'ongoing') {
      for (let i = 0; i < puzzleLevels.length; i++) {
        if (puzzleLevels[i]?.status !== 'completed') {
          puzzleLevels[i].status = 'incomplete';
        }
      }
      if (puzzleLevels[level - 1]?.status !== 'completed') {
        puzzleLevels[level - 1].status = 'ongoing';
      }
      props.updateStatusStorage({ 'puzzle_levels': puzzleLevels });
    }
    if (!props.teacher || (props.teacher && props.teacherView)) updateLevelColors();
    setPreviousLevel(level);
    setLevelData(data.levels[level - 1]);
    const levelDataTemp = data.levels[level - 1];
    if (levelDataTemp.format === 'text') {
      const options = document.querySelectorAll(
        '#puzzle-view .puzzle-option-text',
      );
      for (let i = 0; i < 4; i++) {
        options[i].setAttribute('class', 'puzzle-option-text not-clicked');
        options[i].style.border = '1px solid white';
        options[i].style.backgroundColor = 'white';
        options[i].innerHTML = `(${
          options[i].dataset.option
        }) ${
          resourcesList[level - 1][i + 1]}`;
      }
      document
        .querySelector('#puzzle-view .puzzle-question-picture')
        .setAttribute('src', resourcesList[level - 1][0]);
    } else {
      const optionLabels = document.querySelectorAll(
        '#puzzle-view .option-letter',
      );
      const cards = document.querySelectorAll('.puzzle-option');
      for (let i = 0; i < 4; i++) {
        cards[i].style.border = '1px solid #CCCCCC';
        optionLabels[i].style.backgroundColor = '#CCCCCC';
      }
      document
        .querySelectorAll('#puzzle-view .puzzle-option-explanation')
        .forEach((element) => {
          element.setAttribute('class', 'puzzle-option-explanation');
        });
      document
        .querySelectorAll('#puzzle-view .puzzle-option')
        .forEach((element) => {
          element.setAttribute('class', 'puzzle-option not-clicked');
        });
      const letters = ['A', 'B', 'C', 'D'];
      for (let i = 0; i < 4; i++) {
        const element = document.querySelector(
          `#puzzle-view [data-option=${letters[i]}]`,
        );
        element.setAttribute('src', resourcesList[level - 1][i + 1]);
      }
      document
        .querySelector('.puzzle-question-picture')
        .setAttribute('src', resourcesList[level - 1][0]);
    }
    if (props.statusStorage.puzzle_levels[level - 1]?.status === 'completed') {
      displayAnswers(
        props.statusStorage.puzzle_levels[level - 1].option,
        false,
        false,
        level,
      );
    }
  }
  function displayAnswers(
    clicked,
    immediate,
    answerCheckedTemp = answerChecked,
    currentLevelTemp = currentLevel,
  ) {
    if (!loaded) return;
    if (answerCheckedTemp) return;
    setShowSubmitButton(false);
    setShowSolutionButton(false);
    const statuses = {...props.statusStorage};
    function scrollToBottom() {
      // Get the height of the entire document
      const docHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      );
      // Scroll to the bottom of the page
      window.scrollTo(0, docHeight);
    }
    if (immediate) scrollToBottom();
    setShowPuzzleExplantion(true);
    setAnswerChecked(true);
    if (statuses.puzzle_levels[currentLevelTemp - 1]?.status !== 'completed') {
      statuses.exercise_puzzle_levels_completed++;
      statuses.total_puzzle_levels_completed++;
    }
    statuses.puzzle_levels[currentLevelTemp - 1].status = 'completed';
    updateLevelColors(statuses);
    const letters = ['A', 'B', 'C', 'D'];
    if (data.levels[currentLevelTemp - 1].format === 'text') {
      const options = document.querySelectorAll(
        '#puzzle-view .puzzle-option-text',
      );
      for (let i = 0; i < 4; i++) {
        options[i].setAttribute('class', 'puzzle-option-text');
        if (data.levels[currentLevelTemp - 1].correct === letters[i]) {
          options[i].style.border = '1px solid #AECB2A';
          options[i].style.backgroundColor = '#AECB2A';
        } else if (i === clicked - 1) {
          options[i].style.border = '1px solid #FE7D00';
          options[i].style.backgroundColor = '#FE7D00';
        }
      }
    } else {
      document
        .querySelectorAll('#puzzle-view .puzzle-option')
        .forEach((element) => {
          element.setAttribute('class', 'puzzle-option');
        });
      document
        .querySelectorAll('#puzzle-view .puzzle-option-explanation')
        .forEach((element) => {
          element.setAttribute('class', 'puzzle-option-explanation display');
        });
      const optionLabels = document.querySelectorAll(
        '#puzzle-view .option-letter',
      );
      const cards = document.querySelectorAll('.puzzle-option');
      for (let i = 0; i < 4; i++) {
        if (data.levels[currentLevelTemp - 1].correct === letters[i]) {
          cards[i].style.border = '1px solid #AECB2A';
          optionLabels[i].style.backgroundColor = '#AECB2A';
        } else if (i === clicked - 1) {
          cards[i].style.border = '1px solid #FE7D00';
          optionLabels[i].style.backgroundColor = '#FE7D00';
        }
      }
    }
    if (immediate) {
      if (data.levels[currentLevelTemp - 1].correct === letters[clicked - 1]) {
        setShowGoodJobFeedback(true);
        statuses.puzzle_levels[currentLevelTemp - 1].result = 1;
      } else {
        setShowBadJobFeedback(true);
        statuses.puzzle_levels[currentLevelTemp - 1].result = 0;
      }
      statuses.puzzle_levels[currentLevelTemp - 1].option = clicked;
    }
    props.updateStatusStorage(statuses);
  }
  function optionClicked(option) {
    if (!loaded) return;
    if (answerChecked) return;
    let optionElements;
    let currentlyClickedTemp = currentlyClicked;
    optionElements = document.querySelectorAll(
      '#puzzle-view .puzzle-option, #puzzle-view .puzzle-option-text',
    );
    for (let i = 0; i < optionElements.length; i++) {
      if (i === option) optionElements[i].classList.toggle('focussed');
      else optionElements[i].classList.remove('focussed');
    }
    if (currentlyClicked === option) {
      setCurrentlyClicked(-1);
      currentlyClickedTemp = -1;
    } else {
      setCurrentlyClicked(option);
      currentlyClickedTemp = option;
    }
    if (currentlyClickedTemp === -1) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }
  function submitAnswer() {
    if (props.teacher && !props.teacherView) return;
    if (!loaded) return;
    let level = currentlyClicked;
    if (level > 3) level -= 4;
    level += 1;
    displayAnswers(level, true);
  }
  function handleItemLikedFeedback() {
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    puzzleLevels[currentLevel - 1].feedback = 1;
    props.updateStatusStorage({'puzzle_levels' : puzzleLevels});
    setShowFeedback(false);
  }
  function handleItemDislikedFeedback() {
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    puzzleLevels[currentLevel - 1].feedback = 0;
    props.updateStatusStorage({'puzzle_levels' : puzzleLevels});
    setShowFeedback(false);
  }
  function onFeedbackNextBtnClick() {
    setShowBadJobFeedback(false)
    setShowGoodJobFeedback(false)
    if(currentLevel===data.numLevels) return;
    setCurrentLevel(currentLevel + 1);
    loadLevel(currentLevel + 1);
  }
  return (
    <>
      {!isLoadig ? (
        <div id="puzzle-view" className="content-tab d-hidden" style={props.currentSection === 'puzzle' ? {} : { display: 'none' }}>
          <div className="puzzle-button-holder">
            {props.strapiData.Puzzle.map((lvl, index) => {
              if (index === props.strapiData.Puzzle.length - 1) {
                return (
                  <button
                    key={`btn-${index}`}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                      loadLevel(index + 1);
                    }}
                  >
                    {index + 1}
                  </button>
                );
              }
              return (
                <>
                  <button
                    key={`btn-${index}`}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                      loadLevel(index + 1);
                    }}
                  >
                    {index + 1}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              );
            })}
          </div>
          {resourcesList[currentLevel - 1][0] === null ? (
            <div id="puzzleunderConstruction">
              <h1>Page Under Construction...</h1>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-9">
                  <h3 className="puzzle-title">
                    {data.levels[currentLevel - 1]['Puzzle Name']}
                  </h3>
                  {/* <p className="puzzle-description"></p> */}
                  <p className="puzzle-question-text">
                    {data.levels[currentLevel - 1]['Puzzle Question']
                      .split('\n')
                      .map((line, index) => {
                        if (
                          index
                          === data.levels[currentLevel - 1][
                            'Puzzle Question'
                          ].split('\n').length
                            - 1
                        ) {
                          return line;
                        }
                        return (
                          <>
                            {line}
                            <br />
                          </>
                        );
                      })}
                  </p>
                </div>
                <div className="col-3">
                  <img
                    className="puzzle-question-picture"
                    src={resourcesList[currentLevel - 1][0]}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="d-flex puzzle-option-container justify-content-between"
                style={
                  data.levels[currentLevel - 1].format === 'text'
                    ? { display: 'none' }
                    : {}
                }
              >
                <div className="puzzle-card">
                  <div
                    className="puzzle-option"
                    onClick={() => {
                      optionClicked(0);
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img data-option="A" alt="" />
                    <div className="option-letter">(A)</div>
                  </div>
                </div>
                <div className="puzzle-card">
                  <div
                    className="puzzle-option"
                    onClick={() => {
                      optionClicked(1);
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img data-option="B" alt="" />
                    <div className="option-letter">(B)</div>
                  </div>
                </div>
                <div className="puzzle-card">
                  <div
                    className="puzzle-option"
                    onClick={() => {
                      optionClicked(2);
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img data-option="C" alt="" />
                    <div className="option-letter">(C)</div>
                  </div>
                </div>
                <div className="puzzle-card">
                  <div
                    className="puzzle-option"
                    onClick={() => {
                      optionClicked(3);
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <img data-option="D" alt="" />
                    <div className="option-letter">(D)</div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column puzzle-option-text-container"
                style={
                  data.levels[currentLevel - 1].format !== 'text'
                    ? { display: 'none' }
                    : {}
                }
              >
                <div
                  data-option="A"
                  className="puzzle-option-text"
                  onClick={() => {
                    optionClicked(4);
                  }}
                />
                <div
                  data-option="B"
                  className="puzzle-option-text"
                  onClick={() => {
                    optionClicked(5);
                  }}
                />
                <div
                  data-option="C"
                  className="puzzle-option-text"
                  onClick={() => {
                    optionClicked(6);
                  }}
                />
                <div
                  data-option="D"
                  className="puzzle-option-text"
                  onClick={() => {
                    optionClicked(7);
                  }}
                />
              </div>
              {showPuzzleExplanation && (
                <div className="explanation-box" ref={explanationRef}>
                  <p className="puzzle-explanation">Explanation</p>
                  <pre className="puzzle-explanation-text">
                    {data.levels[currentLevel - 1].Explanation.Text}
                  </pre>
                </div>
              )}
              <div className="row">
                {showSubmitButton && (
                  <div
                    className="col d-flex justify-content-between"
                    style={{ flex: '1' }}
                  >
                    <div className="row" />
                    <button
                      className="puzzle-submit-button"
                      disabled={disabledSubmitButton}
                      onClick={submitAnswer}
                    >
                      Submit
                    </button>
                    <div className="row show-puzzle-solution-container">
                      {props.teacher
                        && props.teacherView
                        && showSolutionButton && (
                          <button
                            id="show-puzzle-solution"
                            style={{
                              fontSize: '1.5vw',
                              marginTop: '0.3vw',
                              lineHeight: '2.2vw',
                            }}
                            onClick={() => {
                              displayAnswers(
                                data.levels[currentLevel - 1].correct,
                                false,
                              );
                            }}
                          >
                            Show solution
                          </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {showBadJobFeedback && (
            <BadJobFeedbackBox
              visibility={showBadJobFeedback}
              setVisibility={setShowBadJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
          {showGoodJobFeedback && (
            <GoodJobFeedbackBox
              visibility={showGoodJobFeedback}
              setVisibility={setShowGoodJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
