import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Container, Typography,
  MenuItem,
  FormControl, Select,
  Backdrop, CircularProgress,
} from "@mui/material";
import MapDataGrid from '../../Components/TableDataGrid/MapDataGrid';
import '../../Styles/Curriculum.css'

const columns = [
  { field: 'module_name', headerName: 'Module Name', flex: 1, hideable: false },
  { field: 'concepts', headerName: 'Concepts', flex: 1, renderCell: (params) => (
    renderCellParams(params)
  ), },
  { field: 'stories', headerName: 'Stories', flex: 1 },
  { field: 'games', headerName: 'Games', flex: 1, renderCell: (params) => (
    renderCellParams(params)
  ), },
  { field: 'python', headerName: 'Python', flex: 1 },
  { field: 'puzzle', headerName: 'Puzzles', flex: 1, renderCell: (params) => (
    renderCellParams(params)
  ),
}
];

const renderCellParams = (params) => {
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {params.value.split('\n').map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index !== params.value.split('\n').length - 1 && <hr />}
            </React.Fragment>
          ))}
    </div>
  );
}

const columnVisibility = {};
export default function CurriculumMap() {
    document.title = "CSSoch | Curriculum Map";
    const [moduleCurriculumMap, setModuleCurriculumMap] = useState(sessionStorage.getItem('curriculum_map') ? JSON.parse(sessionStorage.getItem('curriculum_map')): undefined);
    const [allCurriculum, setAllCurriculum] = useState(sessionStorage.getItem('all_curriculum') ? JSON.parse(sessionStorage.getItem('all_curriculum')): undefined);
    const [currentCurriculum, setCurrentCurriculum] = useState(sessionStorage.getItem('current_curriculum') ? JSON.parse(sessionStorage.getItem('current_curriculum')):null);
    const [currentCurriculumId, setCurrentCurriculumId]= useState(sessionStorage.getItem('current_curriculum') ? JSON.parse(sessionStorage.getItem('current_curriculum')).curriculumn_id: null);
    const [gradeValue, setGradeValue] = useState(sessionStorage.getItem('current_grade') ? JSON.parse(sessionStorage.getItem('current_grade')):null);
    const [rows, setRows] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if( moduleCurriculumMap === undefined ){
        fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}/curriculum-with-grade`)
          .then((response) => response.json())
          .then((data) => {
            setAllCurriculum(data);
          }).catch((error) => {
            console.error(error)
          })
        }
        else {
          if( moduleCurriculumMap[currentCurriculum.curriculumn_id] && moduleCurriculumMap[currentCurriculum.curriculumn_id][gradeValue]){
            setData(moduleCurriculumMap[currentCurriculum.curriculumn_id][gradeValue])
          }
        } 
    },[])

    useEffect(() => {
      if(allCurriculum && !moduleCurriculumMap){
        sessionStorage.setItem('all_curriculum', JSON.stringify(allCurriculum));
        setCurrentCurriculum(allCurriculum[0]);
        setGradeValue(allCurriculum[0].grade_list[0])
        setCurrentCurriculumId(allCurriculum[0].curriculumn_id);
        updateCurriculumData(allCurriculum[0],allCurriculum[0].grade_list[0]);
      }
    },[allCurriculum])
 
    const updateCurriculumDataById =(id) => {
      if(allCurriculum){
        setCurrentCurriculumId(id);
        setCurrentCurriculum(allCurriculum.find(c => c.curriculumn_id === id));
        setGradeValue(allCurriculum.find(c => c.curriculumn_id === id).grade_list[0])
        updateCurriculumData(allCurriculum.find(c => c.curriculumn_id === id),allCurriculum.find(c => c.curriculumn_id === id).grade_list[0]);
      }
    }

    const updateCurriculumData = (curriculum,grd) => {
      let curr_id = curriculum.curriculumn_id
      setIsLoading((pre) => true)
      if(moduleCurriculumMap && moduleCurriculumMap[curr_id] && moduleCurriculumMap[curr_id][grd]){
        setData(moduleCurriculumMap[curr_id][grd])
      }
      else{
      fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}/curriculum/modules/${curr_id}/${grd}`)
        .then((response) => response.json())
        .then((data) => {
          setData(data)
          if(!moduleCurriculumMap || !moduleCurriculumMap[curr_id]){
            setModuleCurriculumMap({...moduleCurriculumMap,[curr_id]:{[grd]:data}})
            sessionStorage.setItem('curriculum_map', JSON.stringify({...moduleCurriculumMap,[curr_id]:{[grd]:data}}));
          }
          else{
            sessionStorage.setItem('curriculum_map', JSON.stringify({...moduleCurriculumMap,[curr_id]:{...moduleCurriculumMap[curr_id],[grd]:data}}));
            setModuleCurriculumMap({...moduleCurriculumMap,[curr_id]:{...moduleCurriculumMap[curr_id],[grd]:data}})
          }
          })
        .catch((error) => {
          console.error(error)
        })
      }
        sessionStorage.setItem('current_curriculum', JSON.stringify(curriculum));
        sessionStorage.setItem('current_grade', JSON.stringify(grd));
    }

    const setData = (data) => {
        const modules = data && data['modules']
        const new_data = modules.map(m => {
          const puzzle_ex = m['Puzzles'].map(g => g['Puzzle Original Name']).join('\n');
          const assessment_puzzle= m['Assessment']['Puzzle'].map(g => g['Puzzle Original Name']).join('\n');
          const games_ex = m['Game Sets'].map(g => g['Game Set Name']).join('\n');
          const assessment_games = m['Assessment']['Game Set'].map(g => g['Game Set Name']).join('\n');
          return {
            module_name: m['Module Original Name'],
            concepts: m.concepts.map(c => c['Concept Name']).join('\n'),
            stories: m['Stories'].map(g => g['Story Name']).join('\n'),
            games: games_ex + '\n' + assessment_games,
            python: m['Python Sets'].map(g => g['Python_set_name']).join('\n'),
            puzzle: puzzle_ex + '\n' + assessment_puzzle
          }
        })
        setRows(new_data);
        setIsLoading((pre)=>false);
    }
    
  return (
    <div className='curriculum-map-container'>
      <Container>
        <div  className='curriculum-map'>
          <Typography
            variant="h6"
            component="h2"
            style={{ padding: 10 }}
          >
            Curriculum Map
          </Typography>
          <div>
          <FormControl>
            <Select
              id="curriculum"
              value={currentCurriculumId}
              onChange={(e) => {
                updateCurriculumDataById(e.target.value);
              }}
            >
              <MenuItem disabled key="placeholder" value="">
                <em>Select Curriculum</em>
              </MenuItem>
              {allCurriculum &&
                allCurriculum.map((option) => (
                  <MenuItem key={option.curriculumn_id} value={option.curriculumn_id}>
                    {option['Curriculum Name']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          
          <FormControl sx={{ marginLeft:  '5px'}}>
            <Select
              id="grades"
              value={gradeValue}
              onChange={(e) => {
                updateCurriculumData(currentCurriculum, e.target.value);
                setGradeValue(e.target.value)

              }}
            >
                <MenuItem disabled key="placeholder" value="">
                    <em>Select Grade</em>
                </MenuItem>
              {currentCurriculum?.grade_list &&
                currentCurriculum?.grade_list.map((option) => (
                  <MenuItem key={option} value={option}>
                    {'Grade' + option }
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          </div>
        </div>
        <Box className="grid-container">
            {rows && 
              (
                <MapDataGrid rows={rows} columns={columns} columnVisibility={columnVisibility} />
              )
            }
        </Box>
        {isLoading && 
          <div className='loader-container'>
            <CircularProgress />
          </div>
        }
      </Container>
    </div>
  )
}
