import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Container, Typography } from "@mui/material";
import MapDataGrid from "../../Components/TableDataGrid/MapDataGrid";
const add_column = [
  { field: "module_name", headerName: "Module Name", flex: 1, hideable: false },
];

const columnVisibility = {};
export default function Modules() {
  document.title = "CSSoch | Module Map";
  const [rows, setRows] = useState(sessionStorage.getItem('module_map') ? JSON.parse(sessionStorage.getItem('module_map')): undefined);
  const [columns, setColumns] = useState(sessionStorage.getItem('module_map_columns') ? JSON.parse(sessionStorage.getItem('module_map_columns')): []);
  useEffect(() => {
    if (rows === undefined) {
      fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}/curriculumn/map`)
        .then((response) => response.json())
        .then((data) => {
          sessionStorage.setItem('module_map', JSON.stringify(data.res));
          setRows(data.res);
          const new_colm = data.columns.map((col, index = 0) => {
            if (index > 2) {
              columnVisibility[col.toLowerCase()] = false;
            }
            return {
              field: col.toLowerCase(),
              headerName: col,
              flex: 1,
            };
          });
          new_colm.unshift(...add_column);
          sessionStorage.setItem('module_map_columns', JSON.stringify(new_colm));
          setColumns(new_colm);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#DDE9F5", height: "89vh" }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Module Map
        </Typography>
        <Box sx={{ height: "79vh", width: "100%" }}>
          {rows ? (
            <MapDataGrid rows={rows} columns={columns} columnVisibility={columnVisibility} />
          ): (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Box>
      </Container>
    </div>
  );
}
