import React, { useRef } from "react";

function FetchInterceptor({ idleTimer, user }) {
  const timeout = useRef();

  const originalFetch = window.fetch;
  window.fetch = function (url, options) {
    // Call the original fetch function with modified parameters
    return originalFetch
      .apply(this, arguments)
      .then((response) => {
        if (
          !response.url.includes(process.env.REACT_APP_API_BASE_URL) ||
          !user?.is_authenticated
        ) {
          return response;
        }
        if (response.redirected) {
          if (response.url.includes("landing")) {
            window.location.href = process.env.REACT_APP_API_BASE_URL;
          } else window.location.href = response.url;
        }

        // Start 29 Minutes Timer from the request it the request is to the cssoch server.
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          if (idleTimer.current.getRemainingTime() / 60000 > 1) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/keep-session-alive`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        }, 29 * 60 * 1000);

        // Return the response
        return response;
      })
      .catch((error) => {
        // Handle fetch errors
        console.error("Fetch error:", error);
        throw error;
      });
  };
  return <></>;
}

export default FetchInterceptor;
