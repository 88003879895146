import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { filterContext } from "../Contexts/filterContext";
import { schoolNameContext } from "../Contexts/schoolName";
import Loader from "../Loader/Loader";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-row:focus":
      {
        outline: "none",
      },
  },
});

export default function TableDataGrid(props) {
  const myvalue = useContext(filterContext);
  const schoolcontext = useContext(schoolNameContext);
  const { type, columns, dataReload, academicYearForSchoolList } = props;
  const [open, setOpen] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [rowList, setRowList] = useState();
  const [reloadData, setReloadData] = useState();
  const [isDeleted, setIsDeleted] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageSize, setPageSize] = React.useState(5);
  const [showColumns, setShowColumns] = useState();
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const classes = useStyles();
  useEffect(() => {
    setRowList(null);
    if (type === "module") {
      fetchModuleData();
    } else {
      setFilterModel(props.filter);
      setColumns();
      if (type === "student") fetchStudentList();
      else if (type === "teacher") fetchTeachersList();
      else if (type === "school") fetchSchoolsList();
    }
  }, [reloadData, dataReload]);
  const setColumns = () => {
    const allColumns = columns.push({
      field: "actions",
      type: "actions",
      flex: type === "school" ? 0.4 : 0.7,
      cellClassName: "actions",
      headerName: "Actions",
      disableColumnMenu: true,
      getActions: (row) => {
        if (type == "school" && row.row.is_school_rolled_over) return [];
        let actionFields = [
          <GridActionsCellItem
            icon={<InfoIcon />}
            label="Info"
            onClick={() =>
              navigate(`/${type}/details`, {
                state: {
                  id: type === "school" ? row.row.school_code : row.row.id,
                  user: location.state?.user,
                  teacherDetails: type === "teacher" ? row.row : null,
                },
              })
            }
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() =>
              navigate(
                type === "school" ? "/edit/school/details" : `/${type}/edit`,
                {
                  state: {
                    mode: "edit",
                    id: type === "school" ? row.row.school_code : row.row.id,
                    user: location.state?.user,
                    teacherDetails: type === "teacher" ? row.row : null,
                  },
                }
              )
            }
            color="inherit"
          />,
        ];
        actionFields =
          type === "school"
            ? [
                ...actionFields,
                <GridActionsCellItem
                  icon={<LaunchIcon />}
                  label="Launch"
                  onClick={() => {
                    schoolcontext.updateSchool(row.row.school_name);
                    navigate("/admin/dashboard", {
                      state: { schoolcode: row.row.school_code },
                    });
                  }}
                  color="inherit"
                />,
              ]
            : [
                ...actionFields,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => handleDelete(row.row.id)}
                  color="inherit"
                />,
              ];
        return type == "school" && row.row.isRollOverPeriodActive
          ? [
              ...actionFields,
              <GridActionsCellItem
                icon={<MoveUpIcon />}
                label="Roll Over"
                onClick={() => {
                  navigate("/school/rollover", {
                    state: {
                      schoolRollOver: true,
                      schoolInfo: row.row,
                      schoolcode: [],
                    },
                  });
                }}
                color="inherit"
              />,
            ]
          : actionFields;
      },
    });
    setShowColumns(columns);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  function fetchModuleData() {
    fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}/curriculumn/map`)
      .then((res) => res.json())
      .then((data) => {
        setRowList(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function fetchStudentList() {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/${type}/lists`)
      .then((res) => res.json())
      .then((data) => {
        if (data.isNewSchool || !data.isRollOverPeriodActive) {
          props.setShowStudentRollOverBtn(false);
        } else {
          props.setShowStudentRollOverBtn(true);
        }
        if (!data.isNewSchool && data.isRollOverPeriodActive) {
          if (
            Object.keys(data["classDataForRollOver"]["gradesFromRollOver"])
              .length === 0 ||
            Object.keys(data["classDataForRollOver"]["gradesToRollOver"])
              .length === 0
          ) {
            props.setDisableAddImportStudent(false);
          } else {
            props.setDisableAddImportStudent(true);
          }
        } else {
          props.setDisableAddImportStudent(false);
        }
        props.setRollOverClassesData(data["classDataForRollOver"]);
        setRowList(data.studentList);
      });
  }
  function fetchTeachersList() {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/${type}/lists`)
      .then((res) => res.json())
      .then((data) => {
        if (!data.isNewSchool && data.isRollOverPeriodActive) {
          if (
            Object.keys(data["classDataForRollOver"]["gradesFromRollOver"])
              .length === 0 ||
            Object.keys(data["classDataForRollOver"]["gradesToRollOver"])
              .length === 0
          ) {
            props.setDisableAddImportTeacher(false);
          } else {
            props.setDisableAddImportTeacher(true);
          }
        } else {
          props.setDisableAddImportTeacher(false);
        }
        const teacherDataTable = [];
        data.teacherList.forEach((teacher) => {
          const { grades } = teacher;
          Object.keys(grades).forEach((grade) => {
            const teacherByGrade = JSON.parse(JSON.stringify(teacher));
            teacherByGrade.grade = grade;
            teacherByGrade.section = grades[grade].sort().map((s, index) => s);
            teacherDataTable.push(teacherByGrade);
          });
        });
        setRowList(teacherDataTable);
      });
  }
  function fetchSchoolsList() {
    if (!academicYearForSchoolList) return;
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/${type}/lists/${academicYearForSchoolList}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRowList(data.schoolList);
      });
  }

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = (user_id) => {
    setIdToDelete(user_id);
    setDialogOpen(true);
  };
  const handleDeleteUser = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_id: idToDelete }),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/${type}/delete`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setDialogOpen(false);
        setIdToDelete();
        setIsDeleted(true);
        setOpen(true);
        setTimeout(() => {
          setReloadData(Math.random());
        }, 3000);
      });
  };
  return (
    <div>
      {isDeleted && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          sx={{ top: "10vh !important" }}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="success"
            sx={{ width: "100%" }}
          >
            {type} deleted successfully
          </Alert>
        </Snackbar>
      )}
      {rowList ? (
        <>
          <h6 style={{ display: "none" }}>{reloadData}</h6>
          <h6 style={{ display: "none" }}>{dataReload}</h6>
          <DataGrid
            style={{
              height: 430,
              width: "100%",
              background: "white",
              [`${gridClasses.columnHeaderTitleContainer} ${gridClasses.iconButtonContainer}`]:
                {
                  visibility: "visible",
                  width: "auto",
                },
            }}
            className={classes.root}
            columns={showColumns}
            getRowId={() => Math.random()}
            rows={rowList}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => {
              setFilterModel(newFilterModel);
              myvalue.updateFilter(newFilterModel, type);
            }}
          />
        </>
      ) : (
        <Loader />
      )}
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete {type.charAt(0).toUpperCase() + type.slice(1)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this {type}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleDeleteUser}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
