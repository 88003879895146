import React, { useEffect, useRef } from "react";

function AssessmentPuzzle(props) {
  const {
    data,
    currentLevel,
    showPuzzleExplanation,
    showSubmitButton,
    disabledSubmitButton,
    teacherView,
    teacher,
    showSolutionButton,
    displayAssesmentAnswers,
    statusStorage,
    updateLevelColors,
    setShowGiveUpButton,
    setNextButtonText,
    updateStatusStorage,
    setShowBadJobFeedbackBox,
    currentlyClicked,
    setCurrentlyClicked,
    setDisableSubmitButton,
    loaded,
    answerChecked,
  } = props;
  const explanationRef = useRef(null)
  useEffect(() => {
    if(showPuzzleExplanation) explanationRef.current.scrollIntoView({ behavior: 'smooth' });
  },[showPuzzleExplanation])
  function giveupLevel() {
    const statuses = { ...statusStorage };
    displayAssesmentAnswers(-1, false);
    if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
      statuses["total_puzzle_levels_completed"]++;
      statuses["assessment_puzzle_levels_completed"]++;
      statuses.assessment_levels[currentLevel - 1].status = "completed";
      statuses["is_puzzle_level_giveup"] = true;
    }
    updateLevelColors(statuses);
    setShowGiveUpButton(false);
    if (currentLevel === data.numLevels) setNextButtonText("Finish");
    statuses.assessment_levels[currentLevel - 1].giveup = 1;
    updateStatusStorage(statuses);
    setShowBadJobFeedbackBox(true);
  }

  function optionClicked(option) {
    if (!loaded) return;
    if (answerChecked) return;
    let optionElements;
    let currentlyClickedTemp = currentlyClicked;
    optionElements = document.querySelectorAll(
      "#puzzle-assesment-view .puzzle-option, #puzzle-assesment-view .puzzle-option-text"
    );
    for (let i = 0; i < optionElements.length; i++) {
      if (i === option) optionElements[i].classList.toggle("focussed");
      else optionElements[i].classList.remove("focussed");
    }
    if (currentlyClicked === option) {
      setCurrentlyClicked(-1);
      currentlyClickedTemp = -1;
    } else {
      setCurrentlyClicked(option);
      currentlyClickedTemp = option;
    }
    if (currentlyClickedTemp === -1) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }

  function submitAnswer() {
    if (!loaded) return;
    let level = currentlyClicked;
    if (level > 3) level -= 4;
    level += 1;
    displayAssesmentAnswers(level, true);
  }

  return (
    <div id="puzzle-assesment-view">
      <div className="puzzle-button-holder" />
      <div className="row">
        <div className="col-9">
          <h3 className="puzzle-title" />
          <p className="puzzle-description" />
          <p className="puzzle-question-text">
            {data.levels[currentLevel - 1]["Puzzle Question"]
              .split("\n")
              .map((line, index) => {
                if (
                  index ===
                  data.levels[currentLevel - 1]["Puzzle Question"].split("\n")
                    .length -
                    1
                ) {
                  return line;
                }
                return (
                  <>
                    {line}
                    <br />
                  </>
                );
              })}
          </p>
        </div>
        <div className="col-3">
          <img className="puzzle-question-picture" alt="" />
        </div>
      </div>
      <div
        className="d-flex puzzle-option-container justify-content-between"
        style={
          data.levels[currentLevel - 1].format === "text"
            ? { display: "none" }
            : {}
        }
      >
        <div className="puzzle-card">
          <div
            className="puzzle-option"
            onClick={() => optionClicked(0)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img data-option="A" alt="" style={{ margin: 0 }} />
            <div className="option-letter">(A)</div>
          </div>
        </div>
        <div className="puzzle-card">
          <div
            className="puzzle-option"
            onClick={() => optionClicked(1)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img data-option="B" alt="" style={{ margin: 0 }} />
            <div className="option-letter">(B)</div>
          </div>
        </div>
        <div className="puzzle-card">
          <div
            className="puzzle-option"
            onClick={() => optionClicked(2)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img data-option="C" alt="" style={{ margin: 0 }} />
            <div className="option-letter">(C)</div>
          </div>
        </div>
        <div className="puzzle-card">
          <div
            className="puzzle-option"
            onClick={() => optionClicked(3)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img data-option="D" alt="" style={{ margin: 0 }} />
            <div className="option-letter">(D)</div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column puzzle-option-text-container"
        style={
          data.levels[currentLevel - 1].format !== "text"
            ? { display: "none" }
            : {}
        }
      >
        <div
          data-option="A"
          className="puzzle-option-text"
          onClick={() => optionClicked(4)}
        />
        <div
          data-option="B"
          className="puzzle-option-text"
          onClick={() => optionClicked(5)}
        />
        <div
          data-option="C"
          className="puzzle-option-text"
          onClick={() => optionClicked(6)}
        />
        <div
          data-option="D"
          className="puzzle-option-text"
          onClick={() => optionClicked(7)}
        />
      </div>
      {showPuzzleExplanation && (
        <div className="explanation-box"  ref={explanationRef}>
          <p className="puzzle-explanation">Explanation</p>
          <pre className="puzzle-explanation-text">
            {data.levels[currentLevel - 1].Explanation.Text}
          </pre>
        </div>
      )}
      {showSubmitButton && (
        <div className="row mt-2 justify-content-between">
          <div className="col" />
          <div className="col d-flex justify-content-center">
            <button
              className="puzzle-submit-button"
              style={{
                marginTop: 0,
                lineHeight: "2.2vw",
              }}
              disabled={disabledSubmitButton}
              onClick={submitAnswer}
            >
              Submit
            </button>
          </div>
          <div className="col d-flex justify-content-end assesment-puzzle-solution">
            {teacher && teacherView && showSolutionButton && (
              <button
                id="show-puzzle-solution"
                style={{
                  fontSize: "1.5vw",
                  marginTop: "0.3vw",
                  lineHeight: "2.2vw",
                }}
                onClick={() => {
                  displayAssesmentAnswers(
                    data.levels[currentLevel - 1].correct,
                    false
                  );
                }}
              >
                Show solution
              </button>
            )}
            {!teacher && (
              <button className="giveup-button ml-auto" onClick={giveupLevel}>
                Skip Level
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AssessmentPuzzle;
