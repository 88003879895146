import React from "react";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg"
import {
  Dialog, DialogTitle, IconButton, styled,
} from '@mui/material/';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    fontFamily: 'rajdhani-medium',
    border: '3px solid #25A596',
    borderRadius: '4vw',
    maxWidth: '60vw',
    width: '60vw',
    height: '70vh',
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(255,255,255,0.85)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  '& 	.MuiIconButton-root': {
    padding: 0,
  },
  '& 	.MuiSvgIcon-root': {
    height: '1.5vw',
    width: '1.5vw',
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          sx={{
            height: '0.5vw',
            poadding: 0,
            position: 'absolute',
            right: '1vw',
            top: '1.5vw',
            color: '#343a40',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function LevelButtons(props) {
  let pythonLevelButtonLabel = 1;
  return (
    <div className="python-button-holder">
      {props.levels.map((lvl, index) => {
        if (index === props.levels.length - 1) {
          return lvl.DemoLevel ? 
            <BootstrapTooltip placement="bottom" title="Demo Level">
              <button
                className={`demo-level-btn ${props.currentLevel === index + 1 ? "active" : ""}` }
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </BootstrapTooltip>
            :
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={props.statusStorage.python_levels[index].status === "completed" ? { backgroundColor: "#25A596" } : props.statusStorage.python_levels[index].status === "ongoing" ? { backgroundColor: "#AECB2A" } : {}}
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
              >
              { pythonLevelButtonLabel++ }
            </button>
        }
        return  lvl.DemoLevel ? (
          <>
            <BootstrapTooltip placement="bottom" title="Demo Level">
              <button
                className={`demo-level-btn ${props.currentLevel === index + 1 ? "active" : ""}` }
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </BootstrapTooltip>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
          )
          :
          <>
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={props.statusStorage.python_levels[index].status === "completed" ? { backgroundColor: "#25A596" } : props.statusStorage.python_levels[index].status === "ongoing" ? { backgroundColor: "#AECB2A" } : {}}
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
            >
              { pythonLevelButtonLabel++ }
            </button>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
        })}
    </div>
  );
}
