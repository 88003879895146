import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch} from 'react-redux';
import StoryButton from "../ModuleComponentsButtons/StoryButton";
import GameButton from "../ModuleComponentsButtons/GameButton";
import PuzzleButton from "../ModuleComponentsButtons/PuzzleButton";
import PythonButton from "../ModuleComponentsButtons/PythonButton";
import AssesmentButton from "../ModuleComponentsButtons/AssesmentButton";
import ProjectButton from "../ModuleComponentsButtons/ProjectButton";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import { Link } from "react-router-dom";
import "../../Styles/Global.css";
import "./ModuleSideBar.css";
export default function ModuleSideBar(props) {
  const navigate = useNavigate();
  const {
    backLink,
    statusStorage,
    structure,
    handleCurrentSectionChange,
    currentSection,
    teacher = false,
    userId,
    moduleID,
    grade,
    className,
    teacherView = false,
    moduleNo,
  } = props;
  const [popUpWindowHandle, setPopUpWindowHandle] = useState();
  const [backLinkState, setBackLinkState] = useState({
    module: moduleID,
    moduleNo: moduleNo,
    user: teacherView ? null : userId,
    type: "module",
  });
  const dispatch = useDispatch()

  return (
    <nav className='module-side-nav d-flex flex-column justify-content-start align-items-center'>
      {!teacher ? (
        <Link to={backLink} style={{ color: "black", textDecoration: "none" }}>
          <span className='module-return-dashboard d-flex align-items-center'>
            <img src={ArrowLeft} alt='' />
            Back
          </span>
        </Link>
      ) : (
        <span
          className='module-return-dashboard d-flex align-items-center'
          onClick={() => {
            if (teacherView)
              navigate(`/dashboard/${grade}/${className}`, {
                state: backLinkState,
              });
            else
              navigate(`/dashboard/${grade}/${className}`, {
                state: backLinkState,
              });
          }}
        >
          <img src={ArrowLeft} alt='' />
          Back
        </span>
      )}
      {structure && structure.includes("story") && (
        <StoryButton
          status={statusStorage?.story_status}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "story"}
          linkState={props.state}
        />
      )}
      {structure && structure.includes("game") && (
        <GameButton
          status={statusStorage?.game_status}
          isStoryPresent = {structure.includes("story") ? true : false}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "game"}
          linkState={props.state}
        />
      )}
      {structure && structure.includes("python") && (
        <PythonButton
          isStoryPresent = {structure.includes("story") ? true : false}
          isGamePresent = {structure.includes("game") ? true : false}
          status={statusStorage.python_status}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "python"}
          linkState={props.state}
        />
      )}
      {structure && structure.includes("puzzle") && (
        <PuzzleButton
          isStoryPresent = {structure.includes("story") ? true : false}
          isGamePresent = {structure.includes("game") ? true : false}
          isPythonPresent = {structure.includes("python") ? true : false}
          status={statusStorage?.puzzle_status}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "puzzle"}
          linkState={props.state}
        />
      )}
      {structure && structure.includes("assessment") && (
        <AssesmentButton
          isStoryPresent = {structure.includes("story") ? true : false}
          isGamePresent = {structure.includes("game") ? true : false}
          isPuzzlePresent = {structure.includes("puzzle") ? true : false}
          isPythonPresent = {structure.includes("python") ? true : false}
          assesmentDetails={{
            status: statusStorage?.assessment_status,
            isgamelevelgiveup: statusStorage?.is_game_level_giveup,
            ispuzzlelevelgiveup: statusStorage?.is_puzzle_level_giveup,
            ispythonlevelgiveup: statusStorage?.is_python_level_giveup,
          }}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "assessment"}
          teacher={teacher}
          linkState={props.state}
        />
      )}
      {structure && structure.includes("project") && (
        <ProjectButton
          status={statusStorage.project_status}
          onButtonClicked={handleCurrentSectionChange}
          active={currentSection === "project"}
          linkState={props.state}
        />
      )}
      {props.teacher && props.teacherView && (
        <button
          id='notes-id'
          onClick={() => {
            let handle = popUpWindowHandle;
            const windowFeatures =
              "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=620,height=620,dependent";
            if (handle == null || handle.closed) {
              handle = window.open(
                `${process.env.REACT_APP_API_BASE_URL}/notes/${props.grade}/${props.className}/module/${props.moduleNo}/${props.moduleID}`,
                "mozillaWindow",
                windowFeatures
              );
            } else {
              handle.focus();
            }
            if (!handle) {
              window.alert(
                "The window wasn't allowed to open. This is likely caused by built-in popup blockers."
              );
            } else {
              localStorage.setItem("notesHandleName", "mozillaWindow");
            }
            setPopUpWindowHandle(handle);
          }}
        >
          Notes
        </button>
      )}
    </nav>
  );
}
