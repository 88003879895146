import React, { useEffect, useState, useContext, useRef } from "react";
import TextCell from "../../Components/NotebookCells/TextCell";
import GameCell from "../../Components/NotebookCells/GameCell";
import clickSound from "../../Assets/sounds/click_sound.mp3";
import PuzzleCell from "../../Components/NotebookCells/PuzzleCell";
import "../../Styles/Module.css";
import "../../Styles/Notebook.css";
import { NotebookContext } from "../../Components/Contexts/NotebookContext";
import PythonCell from "../../Components/NotebookCells/PythonCell";
import TableCell from "../../Components/NotebookCells/TableCell";
import InformatonBar from "../../Components/NavBars/InformatonBar";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import NotebookLevelButtons from "../../Components/NotebookCells/NotebookLevelButtons";
import NotebookSideBar from "../../Components/NavBars/NotebookSideBar";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GameButton from "../../Components/ModuleComponentsButtons/GameButton";
import PythonButton from "../../Components/ModuleComponentsButtons/PythonButton";
import PuzzleButton from "../../Components/ModuleComponentsButtons/PuzzleButton";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRight: "none",
  borderLeft: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon sx={arrowStyle} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const arrowStyle = { color: "white", fontSize: "3vw" };

function Notebook(props) {
  const {
    notebookId,
    notebookStrapiData,
    notebookData,
    isLoading,
    uploadNotebookData,
    currentSectionActivated,
    setCurrentSectionActivated,
    isTeachModule,
    grade,
    className,
    moduleNumber,
    isTeacherSeeingStudentWork,
    studentUserId,
    state,
    setDisableRunPythonButton,
    dataLoadingCompleted,
  } = useContext(NotebookContext);
  const { user } = useContext(authUserContext);
  const {
    setIsModulePage,
    setIsTeacherDashboard,
    setNavbarModuleData,
    setTeacherGrade,
    setTeacherSection,
  } = useContext(PrimaryNavBarContext);
  const [stateChanges, setStateChanged] = useState(true);
  const [accordionNotOpen, setAccordionNotOpen] = useState({
    exercise: {},
    assessment: {},
  });
  const exerciseCsvDataFiles = useRef([]);
  const assessmentCsvDataFiles = useRef([]);
  const isPythonProgramRunning = useRef(false);
  const isPythonGraphicProgramRunning = useRef(false);
  const unmountingPython = useRef(false);
  document.title = `CSSoch | ${currentSectionActivated} Notebook`;
  const [backLinkState, setBackLinkState] = useState({
    module: notebookId,
    moduleNo: moduleNumber,
    type: "notebook",
    user: isTeachModule ? null : studentUserId,
  });
  const [selctedLevel, setSelectedLevel] = useState(-1);
  useEffect(() => {
    setIsModulePage(true);
    setIsTeacherDashboard(false);
    setTeacherGrade(grade);
    setTeacherSection(className);
  }, []);
  useEffect(() => {
    if (!currentSectionActivated || !dataLoadingCompleted) return;
    let updataStatusData = {};
    if (currentSectionActivated === "Exercise") {
      if (notebookData?.exercise_status !== "completed") {
        updataStatusData["exercise_status"] = "ongoing";
      }
      if (notebookData?.assessment_status === "ongoing") {
        updataStatusData["assessment_status"] = "incomplete";
      }
    } else {
      if (notebookData?.assessment_status !== "completed") {
        updataStatusData["assessment_status"] = "ongoing";
      }
      if (notebookData?.exercise_status === "ongoing") {
        updataStatusData["exercise_status"] = "incomplete";
      }
    }
    if (Object.keys(updataStatusData).length)
      uploadNotebookData(updataStatusData);
    setDisableRunPythonButton(false);
  }, [currentSectionActivated]);
  useEffect(() => {
    if (currentSectionActivated && notebookStrapiData) {
      setNavbarModuleData({ moduleName: notebookStrapiData?.notebookName });
    }
  }, [isTeachModule, currentSectionActivated, notebookStrapiData]);

  function handleCurrentSectionChange(newSection, immediate = false) {
    if (newSection !== currentSectionActivated) {
      setStateChanged((value) => !value);
      if (immediate) {
        const audio = new Audio(clickSound);
        audio.volume = 0.3;
        audio.play();
      }
      setCurrentSectionActivated(
        newSection === "story" ? "Exercise" : "Assessment"
      );
    }
  }

  if (isLoading) {
    return <p>Page is Loading...</p>;
  }

  return (
    <>
      <NotebookSideBar
        notebook={true}
        handleCurrentSectionChange={handleCurrentSectionChange}
        currentSection={currentSectionActivated}
        backLink={
          isTeachModule
            ? `/dashboard/${grade}/${className}`
            : isTeacherSeeingStudentWork
            ? `/dashboard/${grade}/${className}`
            : `/home?module=${notebookId}&type=notebook`
        }
        backLinkState={backLinkState}
        state={state}
        teacher={user?.role.includes("teacher")}
      />
      <div className='notebook-container'>
        {isTeacherSeeingStudentWork && (
          <div style={{ fontFamily: "rajdhani-medium" }}>
            <InformatonBar textToDisplay={`${notebookData?.studentName}`} />
          </div>
        )}
        <div className='notebook-title-header'>
          <NotebookLevelButtons
            data={
              currentSectionActivated === "Exercise"
                ? notebookStrapiData.exerciseCells
                : notebookStrapiData.assessmentCells
            }
            notebookData={notebookData}
            notebookType={currentSectionActivated}
            isTeachModule={isTeachModule}
            selctedLevel={selctedLevel}
            setSelectedLevel={setSelectedLevel}
            setAccordionNotOpen={setAccordionNotOpen}
            accordionNotOpen={accordionNotOpen}
          />
        </div>
        {currentSectionActivated === "Exercise" && (
          <div className='exercisenotebook-container'>
            <div className='notebook-exercise-levels'>
              {notebookStrapiData.exerciseCells.map((cell, index) => {
                if (cell.type === "text") {
                  return (
                    <div
                      id={`exercise-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <TextCell cellcontent={cell} cellIndex={index} />
                    </div>
                  );
                } else if (cell.type === "game") {
                  return (
                    <div
                      id={`exercise-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["exercise"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            exercise: {
                              ...accordionNotOpen.exercise,
                              [index]: !accordionNotOpen.exercise[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <GameButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Game
                        </AccordionSummary>
                        <AccordionDetails>
                          <GameCell
                            gameSetData={cell}
                            cellIndex={index}
                            notebookType='exercise'
                            resetState={stateChanges}
                            csvDataFiles={exerciseCsvDataFiles.current}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "puzzle") {
                  return (
                    <div
                      id={`exercise-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["exercise"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            exercise: {
                              ...accordionNotOpen.exercise,
                              [index]: !accordionNotOpen.exercise[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <PuzzleButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Puzzle
                        </AccordionSummary>
                        <AccordionDetails>
                          <PuzzleCell
                            puzzleCellData={cell}
                            cellIndex={index}
                            notebookType='exercise'
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "python") {
                  return (
                    <div
                      id={`exercise-cell-${index}`}
                      className='cell-container python-cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["exercise"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            exercise: {
                              ...accordionNotOpen.exercise,
                              [index]: !accordionNotOpen.exercise[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <PythonButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Python
                        </AccordionSummary>
                        <AccordionDetails>
                          <PythonCell
                            unmountingPython={unmountingPython}
                            cellIndex={index}
                            pythonCellData={cell}
                            teacher={false}
                            teacherView={false}
                            isPythonProgramRunning={isPythonProgramRunning}
                            isPythonGraphicProgramRunning={
                              isPythonGraphicProgramRunning
                            }
                            notebookType='exercise'
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "table") {
                  exerciseCsvDataFiles.current = [
                    ...exerciseCsvDataFiles.current,
                    [cell.title, cell.url],
                  ];
                  return (
                    <div
                      id={`exercise-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["exercise"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            exercise: {
                              ...accordionNotOpen.exercise,
                              [index]: !accordionNotOpen.exercise[index],
                            },
                          });
                        }}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          Data Table
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableCell cellIndex={index} tableCellData={cell} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        {currentSectionActivated === "Assessment" && (
          <div className='assessmentnotebook-container'>
            <div className='notebook-assessment-levels'>
              {notebookStrapiData.assessmentCells.map((cell, index) => {
                if (cell.type === "text") {
                  return (
                    <div
                      id={`assessment-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <TextCell cellcontent={cell} />
                    </div>
                  );
                } else if (cell.type === "game") {
                  return (
                    <div
                      id={`assessment-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["assessment"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            assessment: {
                              ...accordionNotOpen.assessment,
                              [index]: !accordionNotOpen.assessment[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <GameButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Game
                        </AccordionSummary>
                        <AccordionDetails>
                          <GameCell
                            gameSetData={cell}
                            cellIndex={index}
                            notebookType='assessment'
                            csvDataFiles={assessmentCsvDataFiles.current}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "puzzle") {
                  return (
                    <div
                      id={`assessment-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["assessment"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            assessment: {
                              ...accordionNotOpen.assessment,
                              [index]: !accordionNotOpen.assessment[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <PuzzleButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Puzzle
                        </AccordionSummary>
                        <AccordionDetails>
                          <PuzzleCell
                            puzzleCellData={cell}
                            cellIndex={index}
                            notebookType='assessment'
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "python") {
                  return (
                    <div
                      id={`assessment-cell-${index}`}
                      className='cell-container python-cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["assessment"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            assessment: {
                              ...accordionNotOpen.assessment,
                              [index]: !accordionNotOpen.assessment[index],
                            },
                          });
                        }}
                        onClick={() => setSelectedLevel((pre) => index)}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <PythonButton
                            key={index}
                            icon={true}
                            notebookView={true}
                            status={"notebookIconColor"}
                          />
                          Python
                        </AccordionSummary>
                        <AccordionDetails>
                          <PythonCell
                            unmountingPython={unmountingPython}
                            cellIndex={index}
                            pythonCellData={cell}
                            teacher={false}
                            teacherView={false}
                            isPythonProgramRunning={isPythonProgramRunning}
                            isPythonGraphicProgramRunning={
                              isPythonGraphicProgramRunning
                            }
                            notebookType='assessment'
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                } else if (cell.type === "table") {
                  assessmentCsvDataFiles.current = [
                    ...assessmentCsvDataFiles.current,
                    [cell.title, cell.url],
                  ];
                  return (
                    <div
                      id={`assessment-cell-${index}`}
                      className='cell-container'
                      key={index}
                    >
                      <Accordion
                        expanded={!accordionNotOpen["assessment"][index]}
                        onChange={() => {
                          setAccordionNotOpen({
                            ...accordionNotOpen,
                            assessment: {
                              ...accordionNotOpen.assessment,
                              [index]: !accordionNotOpen.assessment[index],
                            },
                          });
                        }}
                      >
                        <AccordionSummary
                          aria-controls='panel1-content'
                          id='panel1-header'
                          sx={{
                            ".MuiAccordionSummary-content": {
                              margin: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          Data Table
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableCell cellIndex={index} tableCellData={cell} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Notebook;
