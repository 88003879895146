import { useState, useEffect, useRef } from "react";
import {
  asyncRun,
  asyncConsoleRun,
  setInput,
  interruptCodeExecution,
  interruptCodeExecutionAfterTimeout,
  setPyodideLoaded,
  asyncRunTest,
  loadPyodide,
  clearNamespaces,
} from "../PyWorker";
import { Grid, Stack, styled, Tooltip, tooltipClasses } from "@mui/material";
import GraphicPyodide from "../Python/GraphicsPyodide/graphicPyodide";
import PythonConsole from "../../PythonConsole/PythonConsole";
import Console from "../../PythonConsole/Console";
import PythonInstruction from "../Python/PythonInfo/PythonInstruction";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import {
  pythonMessages,
  testingCode,
} from "../../../Utils/Constants/PythonConstants";
import { formatError } from "../Python/PythonFormatError";import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const CollapsibleInstructionGrid = styled(Grid)(({ theme, expanded }) => ({
  overflow: 'hidden',
  transition: 'max-width 0.3s ease, opacity 0.3s ease',
  maxWidth: expanded ? '50%' : '0',
  opacity: expanded ? 1 : 0,
  [theme.breakpoints.down('sm')]: {
    maxWidth: expanded ? '100%' : '0',
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

export default function AssessmentPython(props) {
  const {
    data,
    display,
    strapiData,
    setShowGoodJobFeedbackBox,
    currentSection,
    currentLevel,
    updateStatusStorage,
    teacher,
    teacherView,
    statusStorage,
    assessmentPythonStartingLevelNumber,
    updateLevelColors,
    showGiveupButton,
    setShowGiveUpButton,
    showErrorFeedbackBox,
    showGoodJobFeedbackBox,
    showBadJobFeedbackBox,
    setNextButtonText,
    setShowBadJobFeedbackBox,
    expanded,
    setExpanded,
  } = props;
  const isGraphicMode = strapiData?.Assessment?.Python?.[0]?.Graphics;
  const teacherSeeingStudentWork = teacher && !teacherView;
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [consoleElement, setConsoleElement] = useState(null);
  const [graphicPyodide, setGraphicPyodide] = useState(null);
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [programCurrentlyRunning, setProgramCurrentlyRunning] = useState(false);
  const [pythonGraphicsSetupCompleted, setPythonGraphicsSetupCompleted] =
    useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [showTestCase, setShowTestCase] = useState();
  const [title, setTitle] = useState("");
  const [preCode, setPreCode] = useState("#Write your code here");
  const [instruction, setInstruction] = useState("Instruction");
  const [conceptDescription, setConceptDescription] = useState(
    "Concept Description"
  );
  const [currentLevelJsonConfig, setCurrentLevelJsonConfig] = useState(null);
  const [preLoadedImage, setPreLoadedImage] = useState(null);
  const [userGraphicCode, setUserGraphicCode] = useState(null);

  const pythonContainerRef = useRef(null);
  const inputBox = useRef(null);
  const testingTimeout = useRef(null);
  const sendInputBtn = useRef(null);
  const isCurrentSectionAssessment = useRef(currentSection === "assessment" ? true : false);
  const consoleId = "assessment-console";
  const editorId = "assessment-editor";
  let aceEditor = null;

  setupAceEditor();
  useEffect(() => {
    setupAceEditor();
    if (display) {
      if (!isRendredOnce) {
        if (!isGraphicMode) loadPyodide();
        let consoleObj = new Console(consoleId);
        if (isPyodideLoaded) {
          consoleObj.enable();
        }
        setConsoleElement(consoleObj);
        if (isGraphicMode) {
          setGraphicPyodide(new GraphicPyodide(consoleObj));
        }
        setIsRenderedOnce(true);
      }
    }
    if(currentSection !== "assessment") {
      isCurrentSectionAssessment.current = false;
      setShowGoodJobFeedbackBox(false);
      if (!isGraphicMode && isProgramRunning) interruptCodeExecution();
      else if (programCurrentlyRunning) stopPythonGraphicsCode();
    }
    else{
      isCurrentSectionAssessment.current = true;
    }
  }, [currentSection, display]);

  useEffect(() => {
    if (!isPyodideLoaded) {
      let interval;
      interval = setInterval(() => {
        setIsPyodideLoaded(setPyodideLoaded());
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
    if (isPyodideLoaded) {
      consoleElement?.enable();
    }
  }, [isPyodideLoaded]);

  useEffect(() => {
    const runFunctionsAsync = async () => {
      if (graphicPyodide) {
        await graphicPyodide.setup();
        graphicPyodide.setOnErrorCallback((error) => {
          console.log(error);
          if (
            error !==
              "Exception: " + pythonMessages.interruptExecutionMessage &&
            !teacherSeeingStudentWork
          ) {
            let assessmentLevels = [...statusStorage.assessment_levels];
            if (assessmentLevels[currentLevel - 1]?.status !== "completed") {
              assessmentLevels[currentLevel - 1].test_cases =
                pythonMessages.compilationErrorMsg;
              updateStatusStorage({ python_levels: assessmentLevels });
            }
          }
          programCompletedRunning();
        });
        setIsPyodideLoaded(true);
        setPythonGraphicsSetupCompleted(true);
      }
    };
    runFunctionsAsync();
  }, [graphicPyodide]);

  useEffect(() => {
    if (display && currentLevel !== null && statusStorage.assessment_levels.length) {
      loadLevel(currentLevel);
    }
  }, [currentLevel, pythonGraphicsSetupCompleted]);

  useEffect(() => {
    if (scrollToBottom && pythonContainerRef.current && showTestCase?.length) {
      pythonContainerRef.current.scrollTop =
        pythonContainerRef.current.scrollHeight;
      setScrollToBottom(false);
    }
  }, [scrollToBottom, showTestCase]);

  async function setupAceEditor() {
    let editor = document.querySelector(`#${editorId}`);
    aceEditor = await window.ace.edit(editor, {
      theme: "ace/theme/cloud",
      mode: "ace/mode/python",
    });
    aceEditor.setOptions({
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,
    });
  }

  async function loadLevel(level = currentLevel) {
    if (isProgramRunning) interruptCodeExecution();
    if (programCurrentlyRunning) stopPythonGraphicsCode();
    setConceptDescription();
    setInstruction();
    pythonContainerRef.current.scrollTop = 0;
    setShowTestCase(undefined);
    setShowGoodJobFeedbackBox(false);
    let graphicType;
    const pythonLevelInfo =
      strapiData?.Assessment?.Python?.[0]?.Levels[
        level - assessmentPythonStartingLevelNumber
      ];
    await fetch(pythonLevelInfo["Config File"])
      .then((res) => res.json())
      .then((result) => {
        graphicType = result.graphicType;
        setCurrentLevelJsonConfig(result);
      });
    setTitle(pythonLevelInfo?.Title);
    setPreCode(pythonLevelInfo?.Pre_loaded_code);
    if(statusStorage.assessment_levels[level - 1]?.giveup) loadSolution();
    setInstruction(pythonLevelInfo?.Hint);
    setConceptDescription(pythonLevelInfo?.["Problem Description"]);
    if (isGraphicMode) {
      setPreLoadedImage(pythonLevelInfo?.["Pre Loaded Image"]);
    }
    if (statusStorage.assessment_levels[level - 1]?.code)
      aceEditor.setValue(statusStorage.assessment_levels[level - 1]?.code, -1);
    else aceEditor.setValue(pythonLevelInfo?.Pre_loaded_code, -1);
    aceEditor.focus();
    aceEditor.gotoLine(1);
    aceEditor.scrollToLine(0, true, true);
    let assessmentLevels = [...statusStorage.assessment_levels];
    if (
      !teacherSeeingStudentWork &&
      statusStorage?.assessment_status !== "completed" &&
      assessmentLevels[level - 1]?.status !== "ongoing"
    ) {
      for (let i = 0; i < assessmentLevels.length; i++) {
        if (assessmentLevels[i]?.status !== "completed") {
          assessmentLevels[i].status = "incomplete";
        }
      }
      if (
        assessmentLevels[level - 1]?.status !== "completed" &&
        assessmentLevels[level - 1]?.status !== "ongoing"
      ) {
        assessmentLevels[level - 1].status = "ongoing";
        updateStatusStorage({ assessment_levels: assessmentLevels });
      }
    }
    if (!teacherSeeingStudentWork && assessmentLevels[level - 1].status !== "completed" && assessmentLevels[level - 1].attempts >= 10) {
      setShowGiveUpButton(true);
    }
    if (isGraphicMode) {
      loadGraphicType(graphicType);
    } else {
      clearNamespaces();
    }
    clearConsoleHandler();
    const codeElements = pythonContainerRef.current.querySelectorAll("code");
    codeElements.forEach((codeElement) => {
      codeElement.classList.add(`language-python`);
    });
    Prism.highlightAll();
  }

  function setOutputToConsole(output, errorOccurred = false) {
    consoleElement.addMessage(output + "\n", errorOccurred);
  }

  function resetCode() {
    aceEditor.setValue(preCode, -1);
  }

  const runCode = async () => {
    clearConsoleHandler();
    setShowTestCase(undefined);
    setIsProgramRunning(true);
    const prog = aceEditor.getValue();
    try {
      inputBox.current.value = "";
      const { results, error, interrupted } = await asyncRun(prog, consoleId);
      console.log(
        `results: ${results}, error: ${error}, interrupted: ${interrupted}`
      );
      if (results) {
        setOutputToConsole(results);
      } else if (error) {
        setShowTestCase(undefined);
        setOutputToConsole(
          formatError(
            error,
            false,
            prog,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage
          ),
          true
        );
      }
      let testingResults;
      let testCases = [];
      if (results !== undefined) {
        setScrollToBottom(true);
        ({ testingResults } = await asyncRunTest(
          prog,
          results,
          currentLevelJsonConfig,
          testingCode
        ));
      }
      // Checking if the level is success or failure
      let success = true;
      if (testingResults === undefined) {
        success = false;
      } else {
        for (let item of testingResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if(testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      let statuses = { ...statusStorage };
      if (!teacherSeeingStudentWork && statuses.assessment_status !== "completed" && isCurrentSectionAssessment.current) {
        if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
          statuses.assessment_levels[currentLevel - 1].code = prog;
          statuses.assessment_levels[currentLevel - 1].attempts += 1;
          if (testingResults) {
            statuses.assessment_levels[currentLevel - 1].test_cases = testCases;
          } else if (interrupted === false) {
            statuses.assessment_levels[currentLevel - 1].test_cases =
              pythonMessages.compilationErrorMsg;
          }
          // Upadate Status
          if (success) {
            statuses.assessment_levels[currentLevel - 1].status = "completed";
            if(!statuses.assessment_python_levels_completed)
              statuses["assessment_python_levels_completed"] = 1;
            else statuses["assessment_python_levels_completed"]++;
            if (!statuses.total_python_levels_completed)
              statuses["total_python_levels_completed"] = 1;
            else statuses["total_python_levels_completed"]++;
            setShowGoodJobFeedbackBox(true);
          }
          else {
            if(statuses.assessment_levels[currentLevel - 1].attempts >= 10){
              setShowGiveUpButton(true);
            }
          }
          updateStatusStorage(statuses);
          updateLevelColors(statuses);
        }
      } else if (!teacherSeeingStudentWork && success && isCurrentSectionAssessment.current) {
        setShowGoodJobFeedbackBox(true);
      }
      if(teacherView) {
        statuses.assessment_levels[currentLevel - 1].code = prog;
        updateStatusStorage(statuses);
      }
    } catch (e) {
      setOutputToConsole(
        formatError(
          e,
          false,
          prog,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage
        ),
        true
      );
    }
    setIsProgramRunning(false);
  };

  function giveupLevel() {
    const statuses = { ...statusStorage };
    loadSolution();
    if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
      if (!statuses.assessment_python_levels_completed)
        statuses["assessment_python_levels_completed"] = 1;
      else statuses["assessment_python_levels_completed"]++;
      if (!statuses.total_python_levels_completed)
        statuses["total_python_levels_completed"] = 1;
      else statuses["total_python_levels_completed"]++;
      statuses.assessment_levels[currentLevel - 1].status = "completed";
      statuses["is_python_level_giveup"] = true;
    }
    updateLevelColors(statuses);
    setShowGiveUpButton(false);
    if (currentLevel === data.numLevels) setNextButtonText("Finish");
    statuses.assessment_levels[currentLevel - 1].giveup = 1;
    updateStatusStorage(statuses);
    setShowBadJobFeedbackBox(true);
  }

  function programCompletedRunning() {
    clearTimeout(testingTimeout.current);
    testingTimeout.current = null;
    if (inputBox.current.value.trim() === "")
      sendInputBtn.current.disabled = true;
    else sendInputBtn.current.disabled = false;
    inputBox.current.disabled = false;
    setProgramCurrentlyRunning(false);
  }

  function loadGraphicType(graphicType) {
    if(!graphicPyodide) return;
    graphicPyodide.setGraphicType(graphicType);
  }

  function runPythonGraphicCode() {
    setShowTestCase(undefined);
    if (programCurrentlyRunning) return;
    let userCode = aceEditor.getValue();
    programRunning(userCode);
    consoleElement?.clear();
    setUserGraphicCode(userCode);
    graphicPyodide.runCode(userCode,'assessment');
    let assessmentLevels = [...statusStorage.assessment_levels];
    if (assessmentLevels[currentLevel - 1]?.status !== "completed" || teacherView) {
      assessmentLevels[currentLevel - 1].code = userCode;
      assessmentLevels[currentLevel - 1].attempts++;
      updateStatusStorage({ assessment_levels: assessmentLevels });
    }
  }

  function stopPythonGraphicsCode() {
    if(statusStorage.assessment_levels[currentLevel - 1].status !== "completed" && statusStorage.assessment_levels[currentLevel - 1].attempts >= 10){
      setShowGiveUpButton(true);
    }
    if (!programCurrentlyRunning) return;
    graphicPyodide.stopExecution();
  }

  function programRunning(userCode = userGraphicCode) {
    testingTimeout.current = setTimeout(() => {
      runTests(userCode);
    }, 3000);
    sendInputBtn.current.disabled = true;
    inputBox.current.disabled = true;
    setProgramCurrentlyRunning(true);
  }

  function runTests(codeToCheck) {
    let testResults = graphicPyodide.runTests(
      codeToCheck,
      currentLevelJsonConfig
    );
    let statuses = { ...statusStorage };
    let testCases = [];
    if (testResults !== undefined) {
      let success = true;
      if (testResults === undefined) {
        success = false;
      } else {
        for (let item of testResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if(testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      setScrollToBottom(true);
      if (!teacherSeeingStudentWork && statuses.assessment_status !== "completed" && isCurrentSectionAssessment.current) {
        if (statuses.assessment_levels[currentLevel - 1]?.status !== "completed") {
          statuses.assessment_levels[currentLevel - 1].test_cases = testCases;
          //Update Status
          if (success) {
            statuses.assessment_levels[currentLevel - 1].status = "completed";
            if (!statuses.total_python_levels_completed)
              statuses["total_python_levels_completed"] = 1;
            else statuses["total_python_levels_completed"]++;
            if(!statuses.assessment_python_levels_completed)
              statuses["assessment_python_levels_completed"] = 1;
            else statuses["assessment_python_levels_completed"]++;
          }
          updateStatusStorage(statuses);
          updateLevelColors(statuses);
        }
        if (success) setShowGoodJobFeedbackBox(true);
        else {
          if(statuses.assessment_levels[currentLevel - 1].attempts >= 10){
            setShowGiveUpButton(true);
          }
        }
      } else if (!teacherSeeingStudentWork && success && statuses.assessment_status === "completed") {
        setShowGoodJobFeedbackBox(true);
      }
    }
  }
  function handleInput(inputElement) {
    if (programCurrentlyRunning) return;
    let command = inputElement.value;
    if (isGraphicMode) {
      if (command.trim() === "") return;
      inputElement.value = "";
      if (command == "") return;
      consoleElement.addCommand(command);
      graphicPyodide.evaluateConsoleCode(command);
    } else {
      if (isProgramRunning) {
        inputElement.value = "";
        setInput(command);
      } else {
        if (command.trim() === "") return;
        inputElement.value = "";
        if (command === "") return;
        consoleElement.addCommand(command);
        evaluateTextConsoleCode(command);
      }
    }
  }

  async function evaluateTextConsoleCode(command) {
    try {
      if (command.trim() === "") return;
      if (command === "") return;
      setTimeout(interruptCodeExecutionAfterTimeout, 3000);
      const { output, error } = await asyncConsoleRun(command);
      console.log(output, error);
      if (output) {
        setOutputToConsole(output);
      } else if (error) {
        setOutputToConsole(
          formatError(
            error,
            true,
            command,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage,
            pythonMessages.timeoutMessage
          ),
          true
        );
      }
    } catch (error) {
      setOutputToConsole(
        formatError(
          error,
          true,
          command,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage,
          pythonMessages.timeoutMessage
        ),
        true
      );
    }
  }
  const consoleInputKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      sendInputBtn.current.disabled = true;
      handleInput(inputBox.current);
    }
  };
  const consoleInputChangeHandler = () => {
    if (inputBox.current.value.trim() === "") {
      sendInputBtn.current.disabled = true;
    } else {
      sendInputBtn.current.disabled = false;
    }
  };
  const consoleSendBtnClickHandler = () => {
    sendInputBtn.current.disabled = true;
    handleInput(inputBox.current);
  };
  const clearConsoleHandler = () => {
    consoleElement?.clear();
  };
  const loadSolution = () => {
    aceEditor.setValue(
      strapiData?.Assessment?.Python?.[0]?.Levels[
        currentLevel - assessmentPythonStartingLevelNumber
      ].Solution,
      -1
    );
    aceEditor.focus();
    aceEditor.gotoLine(1);
    aceEditor.scrollToLine(0, true, true);
  };

  return (
    <>
      <Grid container id="assessment-python-view" sx={display ? {} : { display: "none" }}>
      <div className="python-drawer-box" >
        <BootstrapTooltip placement='bottom' title={expanded ? "Hide" : "Show"} sx={{zIndex: "10"}}>
          <div onClick={() => setExpanded(pre => !pre)}>
            {expanded ? <ArrowLeftIcon /> : <ArrowRightIcon /> }
          </div>
        </BootstrapTooltip>
      </div>
      <CollapsibleInstructionGrid item expanded={expanded}
            className="python-instruction-container"
            sx={{ height: "100%", flex: 1 }}
          >
          <PythonInstruction
            pythonContainerRef={pythonContainerRef}
            showTestCase={showTestCase}
            title={title}
            conceptDescription={conceptDescription}
            instruction={instruction}
          />
        </CollapsibleInstructionGrid>
        <Grid
          item
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,  
            paddingLeft : expanded ? "" : "2em",
          }}
        >
          <div id="container" style={{ margin: "5px" }}>
            <Stack direction="row" spacing={2}>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                onClick={() =>
                  isGraphicMode ? runPythonGraphicCode() : runCode()
                }
                disabled={
                  !isPyodideLoaded ||
                  isProgramRunning ||
                  programCurrentlyRunning
                }
              >
                Run
              </button>
              <button
                disabled={
                  isGraphicMode ? !programCurrentlyRunning : !isProgramRunning
                }
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "red" }}
                onClick={() =>
                  isGraphicMode
                    ? stopPythonGraphicsCode()
                    : interruptCodeExecution()
                }
              >
                Stop
              </button>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw" }}
                onClick={() => resetCode()}
              >
                Reset
              </button>
            </Stack>
            <Stack direction="row" spacing={2}>
              {teacher && teacherView && (
                <button
                  className="reset-code"
                  id="python-solution-button"
                  style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                  onClick={() => loadSolution()}
                >
                  Solution
                </button>
              )}
              {!teacher && showGiveupButton && (
              <button
                className="giveup-button ml-auto"
                disabled={
                  showErrorFeedbackBox ||
                  showGoodJobFeedbackBox ||
                  showBadJobFeedbackBox
                }
                onClick={giveupLevel}
              >
                Skip Level
              </button>
            )}
            </Stack>
          </div>
          <div
            className="content-tab"
            style={display ? {} : { display: "none" }}
          >
            <div
              className="editor-container"
              style={isGraphicMode ? { height: "calc(100% - 1px)" } : {}}
            >
              <div id={editorId} className="editor"></div>
            </div>
            {!isGraphicMode && (
              <div className="console-container">
                <PythonConsole
                  inputBoxRef={inputBox}
                  sendButtonRef={sendInputBtn}
                  onClearConsole={clearConsoleHandler}
                  inputBoxOnKeyDown={consoleInputKeyDownHandler}
                  inputBoxOnChange={consoleInputChangeHandler}
                  onSendButtonClick={consoleSendBtnClickHandler}
                  consoleId={consoleId}
                />
              </div>
            )}
          </div>
        </Grid>
        {isGraphicMode && (
          <Grid
            item
            className="graphic-window-panel"
            style={{ height: "100%" }}
          >
            <div id="assessment-sketch-holder" className="sketch-holder">
              {preLoadedImage != null && (
                <img
                  src={preLoadedImage}
                  style={{
                    height: "100%",
                    width: "100%",
                    display: programCurrentlyRunning ? "none" : "block",
                  }}
                />
              )}
            </div>
            <div className="graphic-console">
              <PythonConsole
                inputBoxRef={inputBox}
                sendButtonRef={sendInputBtn}
                onClearConsole={clearConsoleHandler}
                inputBoxOnKeyDown={consoleInputKeyDownHandler}
                inputBoxOnChange={consoleInputChangeHandler}
                onSendButtonClick={consoleSendBtnClickHandler}
                consoleId={consoleId}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}
