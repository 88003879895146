import React, { useState } from "react";
import {
  CardActionArea,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Card,
  styled,
} from "@mui/material";
import GameSnapshotImg from "../../Assets/snapshots/GameSnapshot.jpg";
import PuzzleSnapshotImg from "../../Assets/snapshots/PuzzleSnapshot.jpg";
import FoxSnapshotImg from "../../Assets/snapshots/MotherFoxDownSnapshot.svg"
import PythonPlaygroundSnapshot from "../../Assets/snapshots/PythonPlaygroundSnapshot.svg";
import PythonGraphicsPlaygroundSnapshot from "../../Assets/snapshots/PythonGraphicsPlaygroundSnapshot.svg";

import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "./CreateProjectModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PythonButton from "../ModuleComponentsButtons/PythonButton";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "rajdhani-medium",
  },
}));

export default function PlaygroundProjects(props) {
  const { type, projects, playgroundProjectIdList } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const gotoPlayground = (project, type) => {
    const state = {
      projectDetails: project,
      type: type,
      section: props.section,
    };
    if (
      project?.projectType === "python" ||
      project?.projectType === "pythonGraphics"
    ) {
      if (type === "view") {
        navigate("/playground/python/", {
          state: state,
        });
      } else {
        navigate("/playground/python", {
          state: state,
        });
      }
    } else {
      if (type === "view") {
        navigate("/playground/view", {
          state: state,
        });
      } else {
        navigate("/playground", {
          state: state,
        });
      }
    }
  };

  const assignImageToProject = (project) => {
    if (project?.projectType === "python") {
      return PythonPlaygroundSnapshot;
    } else if (project?.projectType === "pythonGraphics") {
      return PythonGraphicsPlaygroundSnapshot;
    } else if (
      playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 === 2
    ) {
      return FoxSnapshotImg;
    } else if (playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 === 1) {
      return GameSnapshotImg;
    } else {
      return PuzzleSnapshotImg;
    }
  };

  const assignIconToProject = (project) => {
    if (project?.projectType === "python" || project?.projectType === "pythonGraphics") {
      return <PythonButton key={project} status={"transparent"} icon={true} />;
    } else {
      return <SportsEsportsOutlinedIcon />;
    } 
  };

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        className="playground-headings select-project-heading"
      >
        {type === "Your Projects" ? (
          <>
            Your Projects ( {projects.length + "/" + props?.maxProjectLimit} )
          </>
        ) : (
          type === "Community Projects" && "Community Projects"
        )}
      </Typography>
      <Container className="playground-projects-container" maxWidth={false}>
        {type === "Your Projects" ? (
          <>
            {props?.projects?.map((project) => {
              return (
                <Card
                  key={project?.project_id}
                  className="project-card user-project-card"
                  onClick={() => gotoPlayground(project, "edit")}
                  sx={{
                    backgroundColor: project?.published ? "#25A596" : "#AECB2A",
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={assignImageToProject(project)}
                      alt="project Img"
                      className="project-card-content-container"
                    />
                    <CardContent
                      sx={{
                        justifyContent: "space-between",
                      }}
                      className="project-card-content-content"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "80%",
                        }}
                      >
                        {assignIconToProject(project)}
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="span"
                          className="project-title"
                        >
                          {project.title}
                        </Typography>
                      </div>
                      <DeleteIcon
                        className="project-delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          props?.handleDeleteProjectPopupOpen(project);
                        }}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
            <Card
              className="project-card user-project-card"
              onClick={() => handleOpen()}
              sx={{
                backgroundColor: "#DDE9F5",
              }}
            >
              <CardActionArea>
                <CardMedia
                  alt="project Img"
                  className="project-card-content-container"
                >
                  <AddIcon className="project-card-add-icon" />
                </CardMedia>
                <CardContent className="project-card-content-content">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="span"
                    className="project-title"
                    sx={{
                      marginTop: "4px",
                    }}
                  >
                    Create Project
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </>
        ) : (
          type === "Community Projects" &&
          (projects.length !== 0 ? (
            <>
              {props?.projects?.map((project) => {
                return (
                  <BootstrapTooltip
                    title={project?.student_name}
                    placement="top"
                    key={project.project_id}
                  >
                    <Card
                      key={project.project_id}
                      className="project-card user-project-card"
                      onClick={() => gotoPlayground(project, "view")}
                      sx={{
                        backgroundColor: "#25A596",
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={assignImageToProject(project)}
                          alt="project Img"
                          className="project-card-content-container"
                        />
                        <CardContent className="project-card-content-content">
                          <div className="project-card-title-content">
                            {assignIconToProject(project)}
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="span"
                              className="project-title"
                            >
                              {project.title}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </BootstrapTooltip>
                );
              })}
            </>
          ) : (
            <>
              <div
                className="no-community-projects"
                style={{ padding: "1.5vw" }}
              >
                <p style={{ fontSize: "1.5rem" }}>No Projects Shared Yet</p>
              </div>
            </>
          ))
        )}
      </Container>
      {open && (
        <CreateProjectModal
          projects={props?.createProjectSet}
          canCreateProject={props?.canCreateProject}
          handleClose={handleClose}
          section={props?.section}
          open={open}
          playgroundProjectIdList={playgroundProjectIdList}
          pythonProjectEnabled={props?.pythonProjectEnabled}
          pythonGraphicsEnabled={props?.pythonGraphicsEnabled}
        />
      )}
    </>
  );
}
