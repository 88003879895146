import React, { useContext, useEffect, useState } from "react";
import { NotebookContext } from "../Contexts/NotebookContext";
import { authUserContext } from "../Contexts/AuthUser";

function PuzzleCell(props) {
  const {user} = useContext(authUserContext);
  const { notebookData, uploadNotebookData, isTeachModule, isTeacherSeeingStudentWork } = useContext(NotebookContext);
  const { puzzleCellData, cellIndex, notebookType } = props;
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [puzzleData, setPuzzleData] = useState();
  const options = ["A", "B", "C", "D"];

  function optionClicked(option) {
    if (answerChecked || puzzleData?.status === "completed") return;
    let currentlyClickedTemp = currentlyClicked;
    if (currentlyClicked === option) {
      setCurrentlyClicked(-1);
      currentlyClickedTemp = -1;
    } else {
      setCurrentlyClicked(option);
      currentlyClickedTemp = option;
    }
    if (currentlyClickedTemp === -1) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }

  useEffect(() => {
    if (notebookData) {
      if (
        notebookData[`${notebookType}_levels`] &&
        notebookData[`${notebookType}_levels`][cellIndex]
      ) {
        const cellData = {...notebookData[`${notebookType}_levels`][cellIndex]}
        setPuzzleData(cellData);
        setCurrentlyClicked(
          cellData?.option - 1
        );
      } else {
        setPuzzleData({
          type: "Puzzle",
          status: "incomplete",
          option: -1,
          result: "",
        });
      }
    }
  }, [notebookData]);

  function submitAnswer() {
    if (isTeacherSeeingStudentWork) return;
    if (answerChecked) return;
    setAnswerChecked(true);
    puzzleData.option = currentlyClicked + 1;
    puzzleData.status = "completed";
    puzzleData.result =
      puzzleCellData?.correct === options[currentlyClicked]
        ? "Correct"
        : "Incorrect";

    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  function displaySolution() {
    setAnswerChecked(true);
    puzzleData.option = options.indexOf(puzzleCellData?.correct) + 1;
    puzzleData.status = "completed";
    puzzleData.result = "Correct"
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  function giveupLevel() {
    puzzleData["status"] = "completed";
    puzzleData["giveup"] = 1;
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
      'is_puzzle_level_giveup': 1
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  return (
    <div id='puzzle-view'>
      <div className='row'>
        <div className='col-9'>
          <h3 className='puzzle-title'>{puzzleCellData["puzzleName"]}</h3>
          <p className='puzzle-question-text'>
            {puzzleCellData["puzzleQuestion"].split("\n").map((line, index) => {
              if (
                index ===
                puzzleCellData["puzzleQuestion"].split("\n").length - 1
              ) {
                return line;
              }
              return (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        </div>
        {puzzleCellData["questionImage"] && <div className='col-3'>
          <img
            className='puzzle-question-picture'
            src={puzzleCellData["questionImage"]}
            alt=''
          />
        </div>}
      </div>
      {puzzleCellData.format === "picture" ? (
        <div className='d-flex puzzle-option-container justify-content-between'>
          {options.map((option, index) => {
            return (
              <div key={index} className='puzzle-card'>
                <div
                  className={`puzzle-option image-puzzle-option ${
                    answerChecked || puzzleData?.status === "completed"
                      ? ""
                      : "not-clicked"
                  } ${
                    puzzleData?.status === "completed" &&
                    puzzleCellData.correct === option
                      ? "correct-option"
                      : ""
                  } ${
                    currentlyClicked === index &&
                    puzzleData?.status === "incomplete"
                      ? "focussed"
                      : ""
                  } ${
                    currentlyClicked === index &&
                    puzzleData?.status === "completed" &&
                    puzzleCellData.correct !== option
                      ? "wrong-option"
                      : ""
                  }`}
                  onClick={() => {
                    optionClicked(index);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    data-option={option}
                    alt=''
                    src={
                      option === "A"
                        ? puzzleCellData?.options?.optionA
                        : option === "B"
                        ? puzzleCellData?.options?.optionB
                        : option === "C"
                        ? puzzleCellData?.options?.optionC
                        : puzzleCellData?.options?.optionD
                    }
                  />
                  <div
                    className={`option-letter ${
                      puzzleData?.status === "completed" &&
                      puzzleCellData.correct === option
                        ? "correct-option"
                        : ""
                    } ${
                      currentlyClicked === index &&
                      puzzleData?.status === "completed" &&
                      puzzleCellData.correct !== option
                        ? "image-wrong-option"
                        : ""
                    }`}
                  >
                    ({option})
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='d-flex flex-column puzzle-option-text-container'>
          {options.map((option, index) => {
            return (
              <div
                data-option={option}
                key={index}
                className={`puzzle-option-text text-puzzle-option ${
                  answerChecked || puzzleData?.status === "completed"
                    ? ""
                    : "not-clicked"
                } ${
                  currentlyClicked === index &&
                  puzzleData?.status === "incomplete"
                    ? "focussed"
                    : ""
                } ${
                  puzzleData?.status === "completed" &&
                  puzzleCellData.correct === option
                    ? "correct-option"
                    : ""
                } ${
                  currentlyClicked === index &&
                  puzzleData?.status === "completed" &&
                  puzzleCellData.correct !== option
                    ? "wrong-option"
                    : ""
                }`}
                onClick={() => {
                  optionClicked(index);
                }}
              >
                ({option}) {puzzleCellData?.options?.[`option${option}`]}
              </div>
            );
          })}
        </div>
      )}
      {puzzleData?.status === "incomplete" ? (
        <div className='row'>
          <div
            className='col d-flex justify-content-between'
            style={{ flex: "1" }}
          >
            <div className='row' />
            <button
              className='puzzle-submit-button'
              disabled={disabledSubmitButton}
              onClick={submitAnswer}
            >
              Submit
            </button>
            <div className="row show-puzzle-solution-container">
              {isTeachModule && (
                  <button
                    id="show-puzzle-solution"
                    style={{
                      fontSize: "1.5vw",
                      marginTop: "0.3vw",
                      lineHeight: "2.2vw",
                    }}
                    onClick={displaySolution}
                  >
                    Show solution
                  </button>
                )}
              {notebookType == "assessment" && user?.role?.includes("student") && puzzleCellData.status !== "completed" && (
                <button className="giveup-button ml-auto" onClick={giveupLevel}>
                  Skip Level
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='explanation-box'>
          <p className='puzzle-explanation'>Explanation</p>
          <p className='puzzle-explanation-text'>
            {puzzleCellData?.explanation?.text}
          </p>
        </div>
      )}
    </div>
  );
}

export default PuzzleCell;
